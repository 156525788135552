<div mat-dialog-title fxlayout="row">
    <div>{{'spdf.app.dialog.session.title' | translate}}</div>
</div>

<div mat-dialog-content>
    <div class="data-field">
        <strong>{{data.messageKey1 | translate}}: </strong>{{minsLeft}} mins {{secsLeft}} seconds
    </div>
    <div class="data-field">
        {{data.messageKey2 | translate}}
    </div>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button color="primary" mat-dialog-close (click)="logout()">
        {{'spdf.app.actions.logout.now' | translate}}
    </button>
    <button mat-flat-button color="primary" (click)="extend()">
        {{'spdf.app.actions.extendsession' | translate}}
    </button>
</div>