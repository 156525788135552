<div mat-dialog-title fxlayout="row">
  <div>{{'spdf.app.module.buup.clear.users' | translate}}</div>
  <div class="gt-dialog-close">
    <button mat-icon-button color="primary" attr.aria-label="{{'spdf.app.tooltips.close' | translate}}"
      matTooltip="{{'spdf.app.tooltips.close' | translate}}" mat-dialog-close>
      <mat-icon fontSet="far" fontIcon="fa-times"> </mat-icon>
    </button>
  </div>
</div>

<div mat-dialog-content>
  <div class="data-field">
    {{'spdf.app.module.buup.clear.confirmation.line1' | translate}}
    <br>
    {{'spdf.app.module.buup.clear.confirmation.line2' | translate}}
  </div>
</div>
<div mat-dialog-actions>
  <!-- <mat-spinner *ngIf="creating" diameter="20"></mat-spinner> -->
  <button mat-stroked-button color="primary" mat-dialog-close
    attr.aria-label="{{'spdf.app.module.buup.cancel' | translate}}">
    {{'spdf.app.module.buup.cancel' | translate}}
  </button>
  <button mat-flat-button color="primary" attr.aria-label="{{'spdf.app.module.buup.clearAll' | translate}}"
    (click)="clearAll()">
    {{'spdf.app.module.buup.clearAll' | translate}}
  </button>
</div>