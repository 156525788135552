<div mat-dialog-title fxlayout="row">
  <div>{{'spdf.app.dialog.email.title' | translate}}</div>
</div>

<div mat-dialog-content>
  <mat-form-field appearance="legacy" aria-readonly="true">
    <mat-label>{{'spdf.app.dialog.email.sent.by' | translate}}</mat-label>
    <input matInput value="{{data.emailData.fromSender}}" readonly disabled /></mat-form-field>
  <mat-form-field appearance="legacy" aria-readonly="true">
    <mat-label>{{'spdf.app.dialog.email.subject' | translate}}</mat-label>
    <input matInput value="{{data.emailData.subject}}" readonly disabled /></mat-form-field>
  <mat-form-field appearance="legacy" aria-readonly="true">
    <mat-label>{{'spdf.app.dialog.email.date' | translate}}</mat-label>
    <input matInput value="{{data.emailData.timeEmailReceived}}" readonly disabled /></mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-flat-button color="primary" mat-dialog-close>
    {{'spdf.app.actions.close' | translate}}
  </button>
</div>