<div class="gt-container-xl">
    <mat-toolbar class="gt-titlebar">
        <div class="gt-titlebar-content-wrapper">
            <div class="gt-titlebar-left-content-wrapper">
                <div class="gt-titlebar-main-title mat-typography">
                    <h1>{{'spdf.app.module.bu.title' | translate}}</h1>
                </div>
            </div>
        </div>
    </mat-toolbar>
    <info-banner [primaryLink]="'spdf.app.module.smartPdf.configuration.details'"
        [primaryLinkRoute]="'/configurationReports'" *ngIf="isAdmin && pendingConfigs.length > 0">
        <ng-template>
            {{'spdf.app.module.smartpdf.configuration.notification' | translate}}:
            <ng-container *ngFor="let config of pendingConfigs; let isLast = last">
                <strong>{{config.configurationName}}{{isLast ? '' : ', '}}</strong>
            </ng-container>
        </ng-template>
    </info-banner>
    <mat-expansion-panel hideToggle [expanded]="true">
        <mat-expansion-panel-header #uniquePanelName (click)="uniquePanelName._toggle()">
            <mat-panel-title> {{'spdf.app.module.bu.businessUnits' | translate}}</mat-panel-title>
            <mat-panel-description>
                <button mat-icon-button color="primary" aria-label="'spdf.app.tooltips.refreshPage' | translate"
                    (click)="$event.stopPropagation();fetchBUs()">
                    <mat-icon matTooltip="{{'spdf.app.tooltips.refresh' | translate}}"
                        aria-label="'spdf.app.tooltips.refreshPage' | translate" fontSet="far" fontIcon="fa-redo">
                    </mat-icon>
                </button>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div fxLayout="row wrap" fxFlexFill fxLayoutGap="24px">
            <mat-form-field fxFlex="{{flexValue < 66 ? 'auto' : '100'}}" appearance="legacy"
                class="gt-form-field-without-label gt-quicksearch-input filterWidth" autocomplete="off"
                floatLabel="always">
                <mat-label id="gt-mat-label-1"></mat-label>
                <input matInput [(ngModel)]="buName" (keyup)="applyFilter(buName)" autocomplete="off"
                    placeholder="{{'spdf.app.module.bu.filterPlaceholder' | translate}}">

                <button class="gt-form-field-clear" mat-icon-button matSuffix *ngIf="buName"
                    (click)="clearFilterValue()" matTooltip="{{'spdf.app.tooltips.clear' | translate}}"
                    attr.aria-label="{{'spdf.app.tooltips.clear' | translate}}">
                    <mat-icon> cancel </mat-icon>
                </button>

                <button mat-icon-button matSuffix disabled class="gt-search-icon"
                    attr.aria-label="{{'spdf.app.module.buup.searchIcon' | translate}}">
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
            <div class="checkboxContent pb-16" fxFlex="{{flexValue < 66 ? '153px' : '100'}}">
                <mat-checkbox color="primary" [(ngModel)]="hideEmptyUnits" (change)="toggleUnitSelection($event)">
                    {{'spdf.app.module.bu.labels.hideEmpty' | translate}}</mat-checkbox>
            </div>
            <mat-divider *ngIf="flexValue<66" class="divider-filter" [vertical]="true" role="separator"></mat-divider>
            <mat-form-field fxFlex="{{flexValue < 66 ? '254px' : '100'}}" appearance="legacy"
                class="gt-sortby-select pb-16">
                <label id="gt-mat-label">{{'spdf.app.module.bu.labels.sortBy' | translate}}</label>
                <mat-select [(ngModel)]="defaultSortParam" (selectionChange)="applySortFilter($event)">
                    <mat-option *ngFor="let param of sortParameters" [value]="param.value">
                        {{param.viewValue | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div [hidden]="noUnitsTodisplay">
            <div class="gt-loader-section">
                <div class="gt-loader-shade-normal loader-section-width" *ngIf="isContentLoading">
                    <mat-progress-bar mode="indeterminate" [value]="value" [bufferValue]="bufferValue">
                    </mat-progress-bar>
                </div>
                <mat-table id="businessUnitsTable" [dataSource]="buDataSource" multiTemplateDataRows
                    class="mat-elevation-z0">
                    <ng-container matColumnDef="businessUnit">
                        <mat-header-cell *matHeaderCellDef> {{'spdf.app.module.bu.table.header.bu' | translate}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="gt-text-cell">
                            {{element.businessUnit}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="configurationName">
                        <mat-header-cell *matHeaderCellDef> {{'spdf.app.module.bu.table.header.configName' | translate}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="gt-text-cell">
                            {{element.configurationName}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="invoiceAddress">
                        <mat-header-cell *matHeaderCellDef> {{'spdf.app.module.bu.table.header.eInvoicingAddress' |
                            translate}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="gt-text-cell">
                            {{element.invoiceAddress}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="invoiceEmail">
                        <mat-header-cell *matHeaderCellDef>
                            {{'spdf.app.module.bu.table.header.invoicingEmailAddress' | translate}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="gt-text-cell">
                            {{element.invoiceEmail}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="messageCount">
                        <mat-header-cell *matHeaderCellDef> {{'spdf.app.module.bu.table.header.docsInQueue' |
                            translate}}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="gt-text-cell">
                            <span class="gt-badge"
                                [ngClass]="{'gt-badge-muted': element.messageCount=='0'}">{{element.messageCount}}</span>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef class="action-column">
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" class="action-column">
                            <mat-icon class="gt-drillin-icon">arrow_forward</mat-icon>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" class="gt-drillin-row"
                        (click)="getBusinessUnit(row)"></mat-row>
                </mat-table>
                <mat-divider class="adjust-table-element-in-panel"></mat-divider>
                <!-- <mat-paginator class="adjust-table-element-in-panel" [hidden]="totalUnits==0" [length]="totalUnits" -->
                <mat-paginator class="adjust-table-element-in-panel" [hidden]="totalUnits==0" [length]="totalUnits"
                    [pageSize]="pageSize" [pageSizeOptions]="matPaginatorPageSizeOptions" (page)="onChangePage($event)">
                </mat-paginator>
            </div>
        </div>
        <div *ngIf="noUnitsTodisplay" class="empty-state-custom gt-empty-state-panel-body-container"
            [ngClass.xs]="{'gt-centered-content':true}">
            <div class="gt-row">
                <p class="gt-lead-text">{{'spdf.app.module.bu.text.queueValidation' | translate}}</p>
            </div>
            <div class="gt-row">
                <p>{{'spdf.app.module.bu.text.queueValidation.subText' | translate}}</p>
            </div>
        </div>
    </mat-expansion-panel>
</div>