<div mat-dialog-title fxlayout="row">
    <div>{{title | translate}}</div>
    <div class="gt-dialog-close">
        <button mat-icon-button color="primary" attr.aria-label="{{'spdf.app.tooltips.close' | translate}}"
            matTooltip="{{'spdf.app.tooltips.close' | translate}}" mat-dialog-close [disabled]="saving">
            <mat-icon fontSet="far" fontIcon="fa-times"> </mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content>
    <mat-toolbar #bannerE *ngIf="showErrorBanner" class="mb-16 gt-banner gt-banner-error">
        <mat-icon fontSet="fas" fontIcon="fa-exclamation-circle" class="gt-icon"></mat-icon>
        <span class="cdk-visually-hidden"> Error</span>
        <span class="gt-banner-content mat-body-1">
            <span class="gt-banner-messageblock">{{"spdf.app.module.bu.internal.error" | translate}}</span>
        </span>
        <button mat-icon-button attr.aria-label="{{'spdf.app.tooltips.close' | translate}}"
            (click)="showErrorBanner = false" color="gt-color-info" class="gt-banner-close-btn">
            <mat-icon fontSet="far" fontIcon="fa-times" aria-hidden="false" class="gt-icon"
                matTooltip="{{'spdf.app.tooltips.close' | translate}}">
            </mat-icon>
        </button>
    </mat-toolbar>

    <form [formGroup]="invoiceSenderForm" name="invoiceSenderForm">
        <mat-form-field appearance="legacy" floatLabel="always">
            <mat-label> {{'spdf.app.module.invoiceSettings.emailAddress' | translate}} </mat-label>
            <input matInput name="domain" formControlName="domain" required (keyup)="domainKeyUp()"
                placeholder="{{'spdf.app.module.invoiceSettings.emailAddress.placeholder' | translate}}">
            <mat-error *ngIf="domain?.errors && domain?.errors!['required']">
                <mat-icon>error</mat-icon>
                {{'spdf.app.module.bu.validation.field.required' | translate}}
            </mat-error>
            <mat-error *ngIf="domain?.errors && (domain?.errors!['incorrectDomain'] || domain?.errors!['email'])">
                <mat-icon>error</mat-icon>
                {{'spdf.app.module.bu.validation.field.incorrectFormat' | translate}}
            </mat-error>
            <mat-error *ngIf="domain?.errors && (!domain?.errors!['email'] && domain?.errors!['receivingDomain'])">
                <mat-icon>error</mat-icon>
                {{'spdf.app.module.invoiceSettings.validation.field.receivingDomain' | translate}}
            </mat-error>
            <mat-error *ngIf="domain?.errors && domain?.errors!['alreadyExist']">
                <mat-icon>error</mat-icon>
                {{'spdf.app.module.invoiceSettings.validation.field.alreadyExist' | translate}}
            </mat-error>
            <mat-error class="gt-formfield-information">
                <mat-icon fontSet="fas" fontIcon="fa-info-circle" class="gt-icon"></mat-icon>
                <span class="cdk-visually-hidden">Information</span>
                {{"spdf.app.module.invoiceSettings.emailAddress.infoTip" | translate}}
            </mat-error>
            <mat-hint class="gt-formfield-information">
                <mat-icon fontSet="fas" fontIcon="fa-info-circle" class="gt-icon"></mat-icon>
                <span class="cdk-visually-hidden">Information</span>
                {{"spdf.app.module.invoiceSettings.emailAddress.infoTip" | translate}}
            </mat-hint>
        </mat-form-field>
        <mat-form-field appearance="legacy" floatLabel="always">
            <mat-label> {{'spdf.app.module.invoiceSettings.splitMethod' | translate}} </mat-label>
            <mat-select formControlName="splitMethod" placeholder="{{'spdf.app.module.buup.select' | translate}}">
                <mat-option *ngFor="let separator of splitMethodOptions" [value]="separator.key">
                    {{separator.value}}
                </mat-option>
            </mat-select>

            <mat-hint class="gt-formfield-information" *ngIf="splitMethod?.value == 'barcode'">
                <mat-icon fontSet="fas" fontIcon="fa-info-circle" class="gt-icon"></mat-icon>
                <span class="cdk-visually-hidden">Information</span>
                {{"spdf.app.module.invoiceSettings.splitMethod.infoTip" | translate}}
            </mat-hint>
        </mat-form-field>
        <mat-form-field *ngIf="splitMethod?.value == 'barcode'" appearance="legacy" floatLabel="always">
            <mat-label> {{'spdf.app.module.invoiceSettings.textToBeRecognized' | translate}} </mat-label>
            <input matInput name="textToBeRecognized" formControlName="textToBeRecognized" required
                (blur)="trimInput($event)">
            <mat-error *ngIf="textToBeRecognized?.errors && textToBeRecognized?.errors!['required']">
                <mat-icon>error</mat-icon>
                {{'spdf.app.module.bu.validation.field.required' | translate}}
            </mat-error>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button color="primary" mat-dialog-close
        attr.aria-label="{{'spdf.app.module.buup.cancel' | translate}}" [disabled]="saving">
        {{'spdf.app.module.buup.cancel' | translate}}
    </button>
    <button mat-flat-button color="primary" attr.aria-label="{{okButtonText | translate}}" (click)="ok()"
        [disabled]="saving || showErrorBanner || (domain?.touched && domain?.invalid) || (textToBeRecognized?.touched && textToBeRecognized?.invalid)">
        <div class="gt-spinner-container" *ngIf="saving">
            <mat-progress-spinner diameter="20" mode="indeterminate"> </mat-progress-spinner>
        </div>
        <span *ngIf="!saving">{{okButtonText | translate}}</span>
    </button>
</div>