import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { BaseTranslationService } from '../../app-services/base.translation.service';
import { LocalizationService } from '../../app-services/localization.service';
import { DecimalFields, InvoiceLineData, toGridModel, TaskResponseLineItem, fromGridModel } from './invoice.lines.models';
import { GlobalizeService } from 'src/app/app-services/globalize.service';
import { AppConfig } from 'src/app/app.config';
import { AppUtils } from '../app-util.service';
import { EditableGridManager } from '@basware/gt-editable-grid';
import { EditableValidatingGridComponent } from './editable-validating-grid/editable-validating-grid.component';
import { DecimalValidator } from 'src/app/validators/decimal.validator';

@Component({
  selector: 'invoice-lines',
  templateUrl: './invoice-lines.component.html',
  styleUrls: ['./invoice-lines.component.scss'],
  providers: [
    EditableGridManager]
})
export class InvoiceLinesComponent extends BaseTranslationService implements OnInit, OnChanges {

  @ViewChild(EditableValidatingGridComponent) editableChild: EditableValidatingGridComponent;

  @Input() lineItems: any[][];
  @Input() isDisabled: boolean = true;
  @Output() updatedLineItems = new EventEmitter<any>();
  @Output() isLineItemsValid = new EventEmitter<any>();
  @Output() readFocusOnCanvas = new EventEmitter<any>();

  isLineItemsValid$: boolean = true;
  _mappedLineItems: InvoiceLineData[] = [];
  reloadGrid: boolean = false; // toggle this variable to reload grid data

  constructor(localization: LocalizationService, private gridManager: EditableGridManager<InvoiceLineData>) {
    super(localization);
  }

  ngOnChanges(changes: SimpleChanges) {
    if ("lineItems" in changes) {
      this._mappedLineItems = [];
      this.isLineItemsValid$ = true;
      this.mapToInvoiceLineDataModel([...this.lineItems]);
      this.reloadGrid = !this.reloadGrid;
    }
  }
  ngOnInit(): void {
  }

  _readFocusOnCanvas(event: any) {
    this.readFocusOnCanvas.emit(event);
  }

  addLineItems() {
    this._mappedLineItems = [new InvoiceLineData(this._mappedLineItems.length + 1)]
  }

  updatedRowData(e: any) {
    this._mappedLineItems = e;
    this.updatedLineItems.emit(this.mapToTaskResponseLineItems(e));
  }

  mapToInvoiceLineDataModel(lineItems: any[]) {
    lineItems.forEach((lineItem: any[], index) => {
      let lineData: any = {};
      lineData["rowNumber"] = index + 1;

      lineItem.forEach(item => {
        let key = toGridModel[item["type"]];
        if (DecimalFields.includes(key) && !isNaN(Number(item["value"]))) {
          lineData[key] = { value: AppUtils.localizeDecimal(item["value"]), data: item }
        } else {
          lineData[key] = { value: item["value"].toString(), data: item }
        }
      })
      this._mappedLineItems.push(lineData);
    })
    // console.log(this._mappedLineItems);
  }

  mapToTaskResponseLineItems(mappedLineItems: InvoiceLineData[]) {
    let _updatedLineItems: any[] = []
    mappedLineItems.forEach((lineData: any) => {
      let lineItem = this.lineItems[lineData.rowNumber - 1];
      let newLineItem: any[] = [];
      Object.keys(lineData).forEach(key => {
        if (key != 'rowNumber' && lineData[key]['value'].length > 0) {
          let itemPresent = lineItem ? lineItem.find(item => toGridModel[item["type"]] == key) : undefined;
          let item = itemPresent ? itemPresent : {};
          item = lineData[key]['data'];
          item["type"] = fromGridModel[key];
          item["value"] = lineData[key]['value'];
          if (DecimalFields.includes(key)) {
            let extractedNumber = GlobalizeService.unformatNumber(lineData[key]['value'], AppConfig.USER_CULTURE);
            if (!isNaN(Number(extractedNumber))) {
              item["value"] = Number(extractedNumber);
            }
          }
          newLineItem.push(item);
        }
      })
      if (newLineItem.length > 0) {
        _updatedLineItems.push(newLineItem);
      }
    })
    return _updatedLineItems;
  }

  _isLineItemsValid(e: any) {
    this.isLineItemsValid$ = e;
    this.isLineItemsValid.emit(e);
  }

  updateCellValue(rowId: any, columnIndex: any, updatedCellData: any) {
    if (updatedCellData.value) {
      // fommat to localized number or return as is 
      if (!DecimalValidator.decimalValidatorForValue(updatedCellData.value) && !isNaN(Number(GlobalizeService.unformatNumber(updatedCellData.value, AppConfig.USER_CULTURE)))) {
        updatedCellData.value = AppUtils.localizeDecimal(updatedCellData.value);
      };
    }
    this.editableChild.updateGridCellValue(rowId, columnIndex, updatedCellData);
  }
}


