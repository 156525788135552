<div mat-dialog-title fxlayout="row">
    <div>{{title | translate}}</div>
    <div class="gt-dialog-close">
        <button mat-icon-button color="primary" attr.aria-label="{{'spdf.app.tooltips.close' | translate}}"
            matTooltip="{{'spdf.app.tooltips.close' | translate}}" mat-dialog-close [disabled]="deleting">
            <mat-icon fontSet="far" fontIcon="fa-times"> </mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content>
    <mat-toolbar #bannerE *ngIf="showErrorBanner" class="mb-16 gt-banner gt-banner-error">
        <mat-icon fontSet="fas" fontIcon="fa-exclamation-circle" class="gt-icon"></mat-icon>
        <span class="cdk-visually-hidden"> Error</span>
        <span class="gt-banner-content mat-body-1">
            <span class="gt-banner-messageblock">{{"spdf.app.module.bu.internal.error" | translate}}</span>
        </span>
        <button mat-icon-button attr.aria-label="{{'spdf.app.tooltips.close' | translate}}"
            (click)="showErrorBanner = false" color="gt-color-info" class="gt-banner-close-btn">
            <mat-icon fontSet="far" fontIcon="fa-times" aria-hidden="false" class="gt-icon"
                matTooltip="{{'spdf.app.tooltips.close' | translate}}">
            </mat-icon>
        </button>
    </mat-toolbar>
    {{message | translate}} {{data.rowData.domain}}?
</div>
<div mat-dialog-actions>
    <button mat-stroked-button color="primary" mat-dialog-close
        attr.aria-label="{{'spdf.app.module.buup.cancel' | translate}}" [disabled]="deleting">
        {{'spdf.app.module.buup.cancel' | translate}}
    </button>
    <button mat-flat-button color="primary" attr.aria-label="{{okButtonText | translate}}" (click)="delete()"
        [disabled]="deleting || showErrorBanner">
        <div class="gt-spinner-container" *ngIf="deleting">
            <mat-progress-spinner diameter="20" mode="indeterminate"> </mat-progress-spinner>
        </div>
        <span *ngIf="!deleting">{{okButtonText | translate}}</span>
    </button>
</div>