<div mat-dialog-title fxlayout="row">
    <div>{{'spdf.app.module.buup.unsavedData' | translate}}</div>
    <div class="gt-dialog-close">
        <button mat-icon-button color="primary" attr.aria-label="{{'spdf.app.tooltips.close' | translate}}"
            matTooltip="{{'spdf.app.tooltips.close' | translate}}" mat-dialog-close>
            <mat-icon fontSet="far" fontIcon="fa-times"> </mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content>
    <div class="data-field">
        {{data.messageKey | translate}}
    </div>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button color="primary" mat-dialog-close (click)="discard()">
        {{'spdf.app.module.buup.button.discardChanges' | translate}}
    </button>
    <button mat-flat-button color="primary" (click)="goBack()">
        {{'spdf.app.module.buup.button.goBack' | translate}}
    </button>
</div>