import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ApiService } from '../app-services/api.service';
import { BaseTranslationService } from '../app-services/base.translation.service';
import { CommonService } from '../app-services/common.service';
import { GlobalizeService } from '../app-services/globalize.service';
import { LocalizationService } from '../app-services/localization.service';
import { AppConfig } from '../app.config';
import { IconSnackBarComponent } from '../snackbar/gt-icon-snack-bar.component';
import { AppUtils } from './app-util.service';
import { DynamicFormFieldComponent } from './dynamic-form-field.component';
import { ElementBase } from './element-base';
import { ElementControlService } from './element-control.service';
import { FieldService } from './field.service';
import * as countryLocaleMap from 'country-locale-map';
import { MatDialog } from '@angular/material/dialog';
import { UnsavedDataWarningComponent } from '../business-unit-user-permissions/dialogs/unsaved-data-warning/unsaved-data-warning.component';
import { SendDefaultValuesComponent } from '../business-unit-user-permissions/dialogs/send-default-values/send-default-values.component';
import { SessionService } from '../app-services/session.service';
import { EmailDetailsComponent } from '../dialogs/email-details.component';
import { environment } from 'src/environments/environment';
import { InvoiceLinesComponent } from './invoice-lines/invoice-lines.component';
import { fromGridModel } from './invoice-lines/invoice.lines.models';

declare function aptrinsic(t: any, s: any): any;

export interface Rect {
	name: string, id: string, x: number, y: number, width: number,
	height: number, fill: string, stroke: string, strokewidth: 1, isSelected: boolean, isHighlighted: boolean,
	string: string, page: number
}
export interface RectArea {
	id: any, x: any, y: any, width: any, height: any, isSelected: any, string: any
}

@Component({
	selector: 'app-dynamic-form',
	templateUrl: './dynamic-form.component.html',
	styleUrls: ['./dynamic-form.component.scss'],
	providers: [ElementControlService, FieldService]
})
export class DynamicFormComponent extends BaseTranslationService implements OnInit {

	@ViewChild(DynamicFormFieldComponent) child: DynamicFormFieldComponent;
	@ViewChild(InvoiceLinesComponent) invoiceLineChild: InvoiceLinesComponent;

	docType = '';
	selectedChip: any;
	forceSend = false;
	hideFormControls = false;
	hideFormControlsOnUI = false;
	showSkipSnackbar = true;
	browserLocale: string;
	mode: ProgressSpinnerMode = 'indeterminate';
	spinnerDiameter = 24;
	skippingTask = false;
	processingTask = false;
	loadingImage = false;
	showErrorBanner = false;
	ocrData = [] as any;
	configFields: any[] = [];
	canvas: any;
	canvasParent: any;
	context: any;
	offsetX: any;
	offsetY: any;
	image: any;
	originalIBAN = '';
	originalVATID = '';
	ibanExtracted = false;
	vatIdExtracted = false;

	strokewidth = 2;
	inputs = ['input', 'select', 'textarea', 'mat-chip'];
	taxesAndSumsFields = ['TOTAL_NET_AMOUNT_VALUE', 'TOTAL_TAX_AMOUNT_VALUE', 'TOTAL_GROSS_AMOUNT_VALUE', 'CURRENCY', 'TOTAL_FREIGHT_AMOUNT_VALUE']
	invoiceTaxData: any[] = [];
	prevFocus: any;
	rotation = 0; // degrees
	scale = AppConfig.IMAGE_START_SCALE;
	fitToWidthScale = AppConfig.IMAGE_START_SCALE;
	zooming = false;
	rotating = false;
	textExample = '';
	fieldsInitialValues: Map<any, any> = new Map();
	@Input() fields: ElementBase<any>[] = [];
	@Input() isDialog: boolean;

	@Output() close = new EventEmitter<any>();
	@Output() submit = new EventEmitter<any>();

	visibleFields = <any>[];
	isContentLoading = false;
	isImageContentLoading = false;
	form: UntypedFormGroup;
	payLoad = '';
	persistentCanvasStyle = { transform: '', scale: '', transformOrigin: '' };
	paginator = { endIndex: 999, page: 999, pageConfigs: <any>[] };
	ocrImageList: string[] = [];
	pageAngles: any[] = []
	ocrImageSrc = '';
	readonly startIndex = 1;
	paginatorNavigation = AppConfig.IMAGE_NAVIGATION_VALUES;
	imageCount: number;
	imgControlExpanded = true;
	imgControlCollapsed = false;
	imgControlHidden = false;
	navigationControlExpanded = true;
	flexValue: number;
	taskResponse: any;
	getTaskRequest = { configurationId: '', receivingEmail: '', unitUuid: '', invoiceAddress: '' };
	configApprovalBannerDetails: any;

	skipTaskRequest = {
		receiptHandle: '', configurationId: '', receivingEmail: '', unitUuid: '', nextTask: true, invoiceAddress: '', messageId: '', expectedExpirationTime: ''
	};
	processTaskRequest = {
		bumId: '', receiptHandle: '', configurationId: '', receivingEmail: '', capturedFields: [] as any,
		currentFields: [] as any, unitUuid: '', outputQueueArn: '', roleArn: '', invoiceAddress: '', customerName: '', companyCode: '', enrichDataModified: false,
		QFSFields: [] as any, messageId: '', dimensions: {} as any
	};
	arnDetails = {
		outputQueueArn: '',
		roleArn: ''
	};
	getOcrDataRequest = { ocrUrl: '' };

	changeSelection: boolean = false;
	changeSelectionFocusPageNum: number = -1;

	isAdmin: boolean;

	invoiceLineItems: any[] = [];
	splitAreaSizes: number[] = [70, 30];
	taxBreakdownInConfigurationFields: any;

	constructor(private ecs: ElementControlService, public apiService: ApiService,
		private snackBar: MatSnackBar, public fieldService: FieldService, public appUtil: AppUtils,
		localization: LocalizationService, public route: ActivatedRoute, private commonService: CommonService,
		public globalize: GlobalizeService, public dialog: MatDialog, private router: Router, private cdr: ChangeDetectorRef,
		private activatedRoute: ActivatedRoute
	) {
		super(localization);
		this.isAdmin = this.commonService.isAdmin();
		this.adjustSplitAreaSizes();
	}

	adjustSplitAreaSizes() {
		if (sessionStorage.getItem('splitAreaSizes')) {
			this.splitAreaSizes = JSON.parse(sessionStorage.getItem('splitAreaSizes')!);
		}
	}

	async ngOnInit() {
		this.isContentLoading = true;
		this.browserLocale = navigator.language;
		this.getTaskRequest.configurationId = this.route.snapshot.queryParamMap.get('cfid')!;
		this.getTaskRequest.receivingEmail = this.route.snapshot.queryParamMap.get('ieml')!;
		this.getTaskRequest.unitUuid = this.route.snapshot.queryParamMap.get('uuid')!;
		this.getTaskRequest.invoiceAddress = this.route.snapshot.queryParamMap.get('ia')!;

		this.configApprovalBannerDetails = JSON.parse(sessionStorage.getItem('configurationApproval')!);
		//could be handled if request params are empty, then redirect to home or error page
		if (this.getTaskRequest.configurationId && this.getTaskRequest.receivingEmail
			&& this.getTaskRequest.unitUuid && this.getTaskRequest.invoiceAddress) {
			await this.getTaskInfo(this.getTaskRequest);
			this.isImageContentLoading = true;
			await this.prepareFormAndCanvas();
			this.isImageContentLoading = false;
			//triggering the validation so Warnings appear(if any)
			if (!this.taskResponse.allDocsValidated) {
				this.child.validateForm();
			}
			aptrinsic('track', 'smartPdfInvoiceDetailsPageLoaded');
		} else {
			this.router.navigate([""]);
		}

		(document.getElementById("dynamic-form") as HTMLElement).addEventListener('mousedown', this.handleMouseDownAnywhere);
	}

	get showValidationError() {
		return this.form.get('TOTAL_NET_AMOUNT_VALUE')?.hasError('validationError') ||
			this.form.get('TOTAL_GROSS_AMOUNT_VALUE')?.hasError('validationError') ||
			this.form.get('TOTAL_TAX_AMOUNT_VALUE')?.hasError('validationError')
	}

	validationError = "";
	taxAndSumsValidations() {
		this.validateTotals(); // do once when data loads
		const fieldsToWatch = ['TOTAL_NET_AMOUNT_VALUE', 'TOTAL_TAX_AMOUNT_VALUE', 'TOTAL_GROSS_AMOUNT_VALUE'];
		fieldsToWatch.forEach(field => this.form.get(field)?.valueChanges.subscribe(() => this.validateTotals()));
	}

	validateTotals() {
		let [grossTotal, netTotal, taxTotal] = [
			this.form.get('TOTAL_GROSS_AMOUNT_VALUE'),
			this.form.get('TOTAL_NET_AMOUNT_VALUE'),
			this.form.get('TOTAL_TAX_AMOUNT_VALUE')];

		const isValid = (control: AbstractControl) => control?.valid ? control.value : control?.invalid && control.hasError('validationError');

		const compareTotals = (control1: AbstractControl, control2: AbstractControl, errorControls: any[], clearErrorControls: any[], errorMsg: string) => {
			if (isValid(control1!) && isValid(control2!)) {
				if (this.parseValue(control1) > this.parseValue(control2)) {
					this.validationError = errorMsg;
					errorControls.forEach(eControl => { if (isValid(eControl!)) this.setValidationError(eControl!) });
					return true;
				} else {
					clearErrorControls.forEach(control => this.clearValidationError(control!));
				}
			}
			return false;
		}

		// Check if TOTAL_TAX_AMOUNT_VALUE > TOTAL_GROSS_AMOUNT_VALUE
		if (compareTotals(taxTotal!, grossTotal!, [grossTotal, netTotal, taxTotal], [taxTotal], 'spdf.app.module.bu.validation.taxesAndSums.taxTotal.gt.grossTotal')) return;

		// Check if TOTAL_TAX_AMOUNT_VALUE > TOTAL_NET_AMOUNT_VALUE
		if (compareTotals(taxTotal!, netTotal!, [grossTotal, netTotal, taxTotal], [taxTotal], 'spdf.app.module.bu.validation.taxesAndSums.taxTotal.gt.netTotal')) return;

		// Check if TOTAL_NET_AMOUNT_VALUE > TOTAL_GROSS_AMOUNT_VALUE
		if (compareTotals(netTotal!, grossTotal!, [grossTotal, netTotal], [grossTotal, netTotal], 'spdf.app.module.bu.validation.taxesAndSums.netTotal.gt.grossTotal')) return;

		[grossTotal, netTotal, taxTotal].forEach(control => this.clearValidationError(control!));
	}

	parseValue = (control: AbstractControl) => Number(GlobalizeService.unformatAnyNumber(control?.value));
	setValidationError = (control: AbstractControl) => {
		let errors: ValidationErrors = control.errors!;
		if (!errors) {
			control?.setErrors({ 'validationError': 'spdf.app.module.bu.validation.taxesAndSums.field.warning' });
		}
	};
	clearValidationError = (control: AbstractControl) => {
		let errors: ValidationErrors | null = control.errors!;
		if (errors && errors['validationError']) {
			delete errors['validationError']
		}
		if (errors && Object.keys(errors).length == 0) errors = null;
		control?.setErrors(errors);
	};

	handleMouseDownAnywhere = async (e: any) => {
		if (!this.prevFocus || (this.prevFocus.classList.contains('mat-chip-input')
			&& (this.prevFocus.classList.contains('iban-field') || this.prevFocus.classList.contains('bban-field')))
			|| this.prevFocus.classList.contains('mat-chip') || this.prevFocus.classList.contains('mat-chip-list-wrapper')) {
			this.canvasInit();
			return;
		}
		let elementClicked = e.target as HTMLElement;
		if (elementClicked.id == (this.prevFocus as HTMLElement).id) {
			// additional data attrib check required as empty invoice line cell click is possible
			if (elementClicked.getAttribute('data-x')) {
				await this.goToCorrectPage(elementClicked.getAttribute('data-page'));
				await this.drawHighlightedArea(elementClicked);
				this.scrollToPosition(Number(elementClicked.getAttribute('data-y')), Number(elementClicked.getAttribute('data-x')));
			}
			return;
		}
		let prevFocusShouldNotLooseForElements = ["img-viewer-body", "imageActionIcon", "mat-menu-item"]
		let actionButtonCicked = false;
		for (let i = 0; i < prevFocusShouldNotLooseForElements.length; i++) {
			if (elementClicked.classList.contains(prevFocusShouldNotLooseForElements[i])) {
				actionButtonCicked = true;
				break;
			}
		}
		if (!actionButtonCicked) {
			this.prevFocus = null; //looseFocus
			this.changeSelection = false;
			this.changeSelectionFocusPageNum = -1;
			this.canvasInit(); // reset canvas
		}
	}

	async ngOnDestroy() {
		this.showSkipSnackbar = false;
		await this.checkDirtySkipTask(false, true);
	}

	//prepare task form & paint canvas image
	async prepareFormAndCanvas() {
		//added a validated docs check to avoid loading image viewer
		if (!this.taskResponse.allDocsValidated) {
			this.intializeHeaderDataPane();
			this.fields = this.fieldService.generateFields(this.ocrData, this.configFields);
			//this map contain initial values of fields. This values will be added on QFS request
			this.fields.forEach(element => this.fieldsInitialValues.set(element.label, element.value));
			this.visibleFields = this.getVisibleFields();
			this.form = this.ecs.toFormGroup(this.visibleFields);
			this.imageCount = this.ocrImageList.length;
			let currentPage = 1;
			this.hideFormControlsOnUI = this.hideFormControls;
			await this.getOcrData(this.getOcrDataRequest);
			this.initPaginator(currentPage, this.imageCount);
			await this.initializeDocumentViewer();
			this.taxAndSumsValidations();
		}
	}

	//initialize paginator
	initPaginator(currentPage: number, endIndex: number) {
		let presetConfig = <any>[];
		//preset config for page orientation
		for (var i = 1; i <= endIndex; i++) {
			presetConfig.push({ pageNo: i, orientation: 0 });
		}
		this.paginator = { page: currentPage, endIndex: endIndex, pageConfigs: presetConfig }
	}

	//change page
	async changePage(nav: string) {
		if (this.prevFocus && this.changeSelectionFocusPageNum == -1) {
			this.changeSelectionFocusPageNum = this.paginator.page;
		}
		switch (nav) {
			case 'n':
				this.paginator.page++;
				break;
			case 'p':
				this.paginator.page--;
				break;
			case 'f':
				this.paginator.page = this.startIndex;
				break;
			case 'l':
				this.paginator.page = this.imageCount;
				break;
			default:
				this.paginator.page = parseInt(nav);
				this.changeSelectionFocusPageNum = this.paginator.page;
				break;
		}
		//handle image rotation page wise
		this.rotate('');
		this.isImageContentLoading = true;
		this.ocrImageSrc = this.ocrImageList[this.paginator.page - 1];
		await this.canvasInit();
		this.isImageContentLoading = false;
		this.highlightPrevFocus();
		await this.drawHighlightedArea(this.prevFocus);
	}

	highlightPrevFocus() {
		if (this.prevFocus) {
			this.changeSelection = true;
			(this.prevFocus as HTMLInputElement).focus();
		}
	}

	async intializeHeaderDataPane() {
		//resize observer for fields pane
		new ResizeObserver(x => {
			let panelWidth = (document.getElementsByClassName('mat-expansion-panel-body')[0] as HTMLDivElement)?.offsetWidth;
			if (panelWidth > 0 && panelWidth < 480) {
				this.flexValue = 100
			} else if (panelWidth > 479 && panelWidth < 720) {
				this.flexValue = 50
			} else if (panelWidth > 719 && panelWidth < 960) {
				this.flexValue = 33.33
			} else if (panelWidth > 959 && panelWidth < 1280) {
				this.flexValue = 25
			} else if (panelWidth > 1279 && panelWidth < 1600) {
				this.flexValue = 16.66
			} else if (panelWidth > 1599) {
				this.flexValue = 16.66
			}
			this.cdr.detectChanges();
		}).observe(document.getElementsByClassName('mat-expansion-panel')[0]);
	}

	async initializeDocumentViewer() {
		await this.canvasInit();
		this.canvas.addEventListener('mousedown', this.handleMouseDown);
		this.canvas.addEventListener('mousemove', this.handleMouseMove);
		//resize observer for document pane
		new ResizeObserver(x => {
			let panelWidth = (document.getElementById('docViewer') as HTMLDivElement)?.offsetWidth;
			if (panelWidth > 0 && panelWidth < 480) {
				this.navigationControlExpanded = false;
				this.imgControlExpanded = false;
				this.imgControlCollapsed = false;
				this.imgControlHidden = true;
			} else if (panelWidth > 479 && panelWidth < 720) {
				this.navigationControlExpanded = true;
				this.imgControlExpanded = false;
				this.imgControlCollapsed = false;
				this.imgControlHidden = true;
			} else if (panelWidth > 719) {
				this.navigationControlExpanded = true;
				this.imgControlExpanded = true;
				this.imgControlCollapsed = false;
				this.imgControlHidden = false;
			}
			this.cdr.detectChanges();
		}).observe(document.getElementById('docViewer')!);
	}

	//initialize canvas
	async canvasInit() {
		this.canvas = document.getElementById("canvas");
		this.canvasParent = document.getElementsByClassName('canvas-container')[0] as HTMLElement;
		this.context = this.canvas.getContext("2d");
		this.offsetX = this.canvas.offsetLeft;
		this.offsetY = this.canvas.offsetTop;
		this.image = new Image();
		await this.loadImage();
	}

	rotateThenDrawImage(currentPageAngle: number, imgHeight: any, imgWidth: any) {
		var canvasX = this.canvas.width / 2;
		var canvasY = this.canvas.height / 2;
		this.context.translate(canvasX, canvasY);
		this.context.rotate(currentPageAngle * Math.PI / 180);
		this.context.drawImage(this.image, -imgWidth / 2, -imgHeight / 2);
		this.context.rotate(-currentPageAngle * Math.PI / 180);
		this.context.translate(-canvasX, -canvasY);
	}

	drawImageOnCanvas(imgHeight: any, imgWidth: any) {
		let currentPageAngle = this.pageAngles.find(p => p.pn == this.paginator.page)?.ang
		if (60 < Math.abs(currentPageAngle) && Math.abs(currentPageAngle) <= 90) {
			this.context.width = imgHeight;
			this.context.height = imgWidth;
			this.canvas.width = imgHeight;
			this.canvas.height = imgWidth;
		} else {
			this.context.width = imgWidth;
			this.context.height = imgHeight;
			this.canvas.width = imgWidth;
			this.canvas.height = imgHeight;
		}
		if (currentPageAngle != 0) {
			this.rotateThenDrawImage(Number(currentPageAngle), imgHeight, imgWidth)
		} else {
			this.context.drawImage(this.image, 0, 0);
		}
	}

	//load image promise to be drawnon canvas
	async loadImage() {
		return new Promise<void>((resolve, reject) => {
			this.image.addEventListener("load", async (e: any) => {
				resolve();
				this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
				this.drawImageOnCanvas(e.currentTarget.height, e.currentTarget.width)
				this.loadingImage = false;
			});
			this.image.addEventListener("error", () => {
				this.loadingImage = true;
				this.isImageContentLoading = false;
				//force resolve to continue showing the error msg
				resolve();
			});
			this.image.src = this.ocrImageList[this.paginator.page - 1];
		});
	}

	//draw a shape over canvas
	async draw(stroke: any, fill: any, rect: RectArea) {
		this.context.save();
		this.context.beginPath();
		this.context.fillStyle = fill || AppConfig.FILL_STYLE_HOVER;
		this.context.strokeStyle = stroke || AppConfig.STROKE_STYLE_HOVER;
		this.context.lineWidth = AppConfig.STROKE_WIDTH;
		if (rect) {
			this.context.rect(Number(rect.x) - AppConfig.RECT_PADDING, Number(rect.y) - AppConfig.RECT_PADDING,
				Number(rect.width) + AppConfig.RECT_PADDING * 2, Number(rect.height) + AppConfig.RECT_PADDING * 2);
		}
		this.context.stroke();
		this.context.fill();
		this.context.restore();
	}

	//check if the clicked coordinates fall inside the defined area
	isPointInside(x: any, y: any, rect: Rect) {
		return (x >= rect.x && x <= rect.x + rect.width && y >= rect.y
			&& y <= rect.y + rect.height && this.paginator.page == rect.page);
	}

	getTransformedXY(event: any) {
		let mouseX = Math.floor(this.offsetX);
		let mouseY = Math.floor(this.offsetY);
		let canvasCoord = this.toCanvasCoords(event.clientX, event.clientY, this.scale);
		mouseX = Math.floor(canvasCoord.x)
		mouseY = Math.floor(canvasCoord.y)
		let txCoords = this.getTransformedCoords(mouseX, mouseY,
			this.appUtil.degreesToRadians(this.appUtil.rotationAngleInDegrees));
		//remap X, Y coordinates in case of image rotation
		switch (this.appUtil.rotationAngleInDegrees) {
			case 90:
				mouseX = txCoords.x;
				mouseY = this.canvas.width + txCoords.y;
				break;
			case 180:
				mouseX = this.canvas.width + txCoords.x;
				mouseY = this.canvas.width + txCoords.y;
				break;
			case 270:
				mouseX = this.canvas.width + txCoords.x;
				mouseY = txCoords.y;
				break;
			default:
				break;
		}
		return { newX: mouseX, newY: mouseY };
	}
	//Event listener for canvas mouse down
	handleMouseDown = async (e: any) => {
		let activeElement = document.activeElement;
		let newXY = this.getTransformedXY(e);
		let mouseX = newXY.newX;
		let mouseY = newXY.newY;

		if (activeElement && this.inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
			//focus handling if user selects a iamge section and clicks an empty area with no coordinate linking
			let looseFocus = true;
			let ibanFields: any = [];
			let bbanFields: any = [];
			for (var i = 0; i < this.ocrData.length; i++) {
				if (this.isPointInside(mouseX, mouseY, this.ocrData[i])) {
					//no action if ag grid element is selected
					if (this.prevFocus && !this.prevFocus.classList.contains("aggrid-field")) {
						looseFocus = false;
						await this.canvasInit();
						setTimeout(() => this.prevFocus.focus(), 0);
						let extractedValue = this.ocrData[i].string.toString();
						//handling date picker values
						if (this.prevFocus.classList.contains("mat-datepicker-input")) {
							//persisting original attrib value 
							activeElement.setAttribute('data-orig-date', extractedValue);
							extractedValue = (this.ocrData[i].hasDate || this.ocrData[i].string).toString();
							let dateFormat = this.appUtil.getMomenDateFormat(AppConfig.INVOICE_CULTURE);
							extractedValue = this.appUtil.getMomentParsedDate(extractedValue, dateFormat);
							this.child?.form.controls[activeElement.id].setValue(extractedValue);
							this.child?.form.controls[activeElement.id].markAsDirty();
						}
						//handling decimal values
						else if (this.prevFocus.classList.contains("decimal-field")) {
							const regex = /[^-0-9,\./\s٫’\']/g;
							let extractedDecimalVal = extractedValue.replace(regex, "").trim();
							let extractedNumber: any;
							extractedNumber = GlobalizeService.unformatAnyNumber(extractedDecimalVal);
							if (!isNaN(extractedNumber)) {
								extractedValue = GlobalizeService.formatNumber(
									extractedNumber!, AppConfig.USER_CULTURE || AppConfig.BROWSER_CULTURE);
							}
						}
						if (this.prevFocus.classList.contains("vatId-field")) {
							this.vatIdExtracted = true;
							extractedValue = (this.ocrData[i].hasVATID || this.ocrData[i].string).toString();
						}
						// if (this.prevFocus.classList.contains("iban-field")) {
						// 	this.ibanExtracted = true;
						// 	extractedValue = (this.ocrData[i].hasIBAN || this.ocrData[i].string).toString();
						// }
						activeElement.setAttribute('data-x', this.ocrData[i].x.toString());
						activeElement.setAttribute('data-y', this.ocrData[i].y.toString());
						activeElement.setAttribute('data-width', this.ocrData[i].width.toString());
						activeElement.setAttribute('data-height', this.ocrData[i].height.toString());
						activeElement.setAttribute('value', extractedValue);
						activeElement.setAttribute('data-page', this.paginator.page.toString());
						this.changeSelectionFocusPageNum = this.paginator.page;
						let controlName = activeElement.getAttribute('data-name');
						if (controlName != null && !this.prevFocus.classList.contains("mat-datepicker-input")) {
							if (this.prevFocus.classList.contains("iban-field") || this.prevFocus.classList.contains("mat-chip-list-wrapper")
								|| this.prevFocus.classList.contains("bban-field")) {
								let chipMaxLength = activeElement.getAttribute('maxlength') || 50;
								this.handleChipMappings(extractedValue, this.ocrData[i], ibanFields, bbanFields, controlName, chipMaxLength);
							} else {
								this.form.controls[controlName].setValue(extractedValue);
								this.child?.form.controls[controlName].markAsDirty();
							}

						}
						//handling grid cell values
						if (activeElement.getAttribute('data-rowId')) {
							activeElement.setAttribute('data-x', this.ocrData[i].x.toString());
							activeElement.setAttribute('data-y', this.ocrData[i].y.toString());
							activeElement.setAttribute('data-width', this.ocrData[i].width.toString());
							activeElement.setAttribute('data-height', this.ocrData[i].height.toString());
							activeElement.setAttribute('value', extractedValue);
							activeElement.setAttribute('data-page', this.paginator.page.toString());
							let updatedCellData = {
								"type": fromGridModel[activeElement.getAttribute('data-columnIndex') || ''],
								"value": extractedValue,
								"page": activeElement.getAttribute('data-page'),
								"x": activeElement.getAttribute('data-x'),
								"y": activeElement.getAttribute('data-y'),
								"width": activeElement.getAttribute('data-width'),
								"height": activeElement.getAttribute('data-height'),
							}
							this.invoiceLineChild.updateCellValue(activeElement.getAttribute('data-rowId'),
								activeElement.getAttribute('data-columnIndex'), updatedCellData);
						}
						await this.drawHighlightedArea(activeElement)
						break;
					}
				}
			}
			if (looseFocus) {
				await this.canvasInit();
				this.prevFocus = null;
				this.changeSelection = false;
			}
			looseFocus = true;
		} else {
			//show error on mouse left click only 
			if (e.which == 1) {
				alert(this.tr('spdf.app.snackbar.message.imageClickErrorMsg'));
				await this.canvasInit();
			}
		}
	}

	//Event listener for canvas mouse move
	handleMouseMove = async (e: any) => {
		setTimeout(async () => { await this.mouseStopped(e) }, AppConfig.HOVER_SELECTION_DELAY_IN_MILLIS);
	}
	// Evenet listener callback
	repaintArea: RectArea = <RectArea>{};
	async mouseStopped(e: any) {
		let activeElement = document.activeElement;
		if (activeElement && this.inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
			let newXY = this.getTransformedXY(e);
			let mouseX = newXY.newX;
			let mouseY = newXY.newY;
			let rectArea: RectArea = <RectArea>{};

			for (var i = 0; i < this.ocrData.length; i++) {
				if (this.isPointInside(mouseX, mouseY, this.ocrData[i])) {
					await this.canvasInit();
					rectArea = {
						x: this.ocrData[i].x, y: this.ocrData[i].y, width: this.ocrData[i].width, height: this.ocrData[i].height,
						isSelected: false, id: '', string: this.ocrData[i].string
					};
					await this.draw(AppConfig.STROKE_STYLE_HOVER, AppConfig.FILL_STYLE_HOVER, rectArea);
					await this.drawHighlightedArea(activeElement);
					break;
				}
			}
			//erase the hover rect
			if (!rectArea.x) {
				await this.canvasInit();
			}
			//redraw the selected field rect as canvas was  reinitialized above
			await this.drawHighlightedArea(activeElement);
		}
	}

	async drawHighlightedArea(element: any) {
		if (this.paginator.page == this.changeSelectionFocusPageNum && element) {
			let rectArea = this.getRectArea(element);
			await this.draw(AppConfig.STROKE_STYLE_CLICK, AppConfig.FILL_STYLE_CLICK, rectArea);
			this.repaintArea = rectArea;
		}
	}

	async goToCorrectPage(page: any) {
		if (page != '0') {
			if (this.paginator.page != page) {
				await this.changePage(page);
			}
		}
	}

	getRectArea(element: any) {
		return {
			x: element.getAttribute('data-x'), y: element.getAttribute('data-y'), width: element.getAttribute('data-width'),
			height: element.getAttribute('data-height'), isSelected: element.getAttribute("data-is-selected"),
			id: element.id, string: element.getAttribute('value')
		}
	}

	//save previous element focus for image section click handling
	readFocus = async (e: any) => {
		if (((e.classList.contains('iban-field') || e.classList.contains('bban-field')) && this.selectedChip && !this.selectedChip['page']) ||
			e.classList.contains('mat-chip-list-wrapper') ||
			(e.classList.contains('mat-input-element') &&
				(e.classList.contains('iban-field') || e.classList.contains('bban-field')))) {
			if (e.querySelector('.mat-input-element')) {
				e.querySelector('.mat-input-element')?.focus();
			} else {
				e.focus();
			}
			if (!e.classList.contains('mat-chip')) {
				this.selectedChip = null;
			}
			this.prevFocus = e;
			await this.canvasInit();
			return;
		}
		if (this.prevFocus == e && this.changeSelection) {
			return;
		}
		this.changeSelectionFocusPageNum = this.paginator.page;
		this.prevFocus = e;
		let rectArea: RectArea = this.getRectArea(e);
		Array.from(document.getElementsByClassName("form-field-dyamic")).forEach(function (item, index) {
			if (item == e) {
				item.setAttribute("data-is-selected", "true")
			} else {
				item.setAttribute("data-is-selected", "false")
			}
		});
		// this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
		// if (!(e.classList.contains('mat-chip-input') || e.classList.contains('mat-chip') || e.classList.contains('mat-chip-list-wrapper'))) {
		await this.canvasInit();
		rectArea.isSelected = true;
		await this.goToCorrectPage(e.getAttribute('data-page'));
		await this.draw(AppConfig.STROKE_STYLE_CLICK, AppConfig.FILL_STYLE_CLICK, rectArea);
		this.scrollToPosition(Number(rectArea.y), Number(rectArea.x));
		// }
	}

	async readFocusForGridItems(gridElement: any) {
		let element = gridElement['data'];
		if (!element || Object.keys(element).length == 0) {
			// when empty cell is in focus, remove highlight from canvas
			await this.canvasInit();
			// return;
		}
		let activeElement = document.activeElement;
		this.prevFocus = activeElement;
		if (Object.keys(element).length > 0 && activeElement && this.inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
			if (['x', 'y', 'width', 'height', 'page'].every(prop => element[prop])) {
				activeElement.setAttribute('data-x', element.x.toString());
				activeElement.setAttribute('data-y', element.y.toString());
				activeElement.setAttribute('data-width', element.width.toString());
				activeElement.setAttribute('data-height', element.height.toString());
				activeElement.setAttribute('value', element.value);
				activeElement.setAttribute('data-page', element.page.toString());
				await this.readFocus(activeElement);
			}
		}
		// force setting some mandatory attributes reqd for calculation
		activeElement?.setAttribute('data-rowId', gridElement.gridRowId.toString());
		activeElement?.setAttribute('data-columnIndex', gridElement.gridColumnIndex.toString());
	}

	//canvas rotate
	rotate(direction: string) {
		this.highlightPrevFocus();
		if (this.paginator.pageConfigs.length == 0) {
			return;
		}
		this.rotation = this.paginator.pageConfigs.find((e: any) => e.pageNo == this.paginator.page).orientation;
		if (direction == 'right') {
			this.rotation += 90;
		} else if (direction == 'left') {
			this.rotation -= 90;
		}
		if (this.rotation == 450) {
			this.rotation = 90;
		} else if (this.rotation == -90) {
			this.rotation = 360 - 90;
		}
		this.appUtil.setRotateAngleInDegree(this.rotation);
		let persistedVar = '';
		if (this.rotation == 90) {
			persistedVar = `rotate(90deg) translateY(-100%)`;
			this.canvas.style.transform = `rotate(90deg) translateY(-100%)`;
			this.canvas.style.transform = `rotate(90deg) scale(${this.scale}) translateY(-100%)`;
			this.canvas.style.transformOrigin = 'top left';
		} else if (this.rotation == 180) {
			persistedVar = `rotate(180deg) translateX(-100%)`;
			this.canvas.style.transform = `rotate(180deg) translateX(-100%)`;
			this.canvas.style.transform = `rotate(180deg) scale(${this.scale}) translateX(-100%)`;
			this.canvas.style.transformOrigin = '0';
		} else if (this.rotation == 270) {
			persistedVar = `rotate(270deg) translateX(-100%)`;
			this.canvas.style.transform = `rotate(270deg) translateX(-100%)`;
			this.canvas.style.transform = `rotate(270deg) scale(${this.scale}) translateX(-100%)`;
			this.canvas.style.transformOrigin = 'top left';
		} else {
			this.canvas.style.transform = '';
			this.canvas.style.transform = `scale(${this.scale})`;
		}
		this.persistentCanvasStyle.transform = persistedVar;
		this.persistentCanvasStyle.transformOrigin = this.canvas.style.transformOrigin;
		// this.scale = AppConfig.IMAGE_START_SCALE;
		this.paginator.pageConfigs.find((e: any) => e.pageNo == this.paginator.page).orientation = this.rotation;
	}

	//canvas zoom in
	zoomIn() {
		this.highlightPrevFocus();
		let newScale = Math.round((this.scale + 0.1) * 100) / 100;
		if (newScale <= AppConfig.IMAGE_MAX_SCALE) {
			this.scale = newScale;
			this.canvas.style.transform = '';
			this.canvas.style.transform = `scale(${this.scale})` + this.persistentCanvasStyle.transform;
			if (this.canvas.style.transform.indexOf('180') != -1) {
				this.canvas.style.transformOrigin = '-5% 65%';
				// this.canvas.style.transformOrigin = 'left bottom';
			} else {
				this.canvas.style.transformOrigin = 'top left';
			}
			this.persistentCanvasStyle.scale = `scale(${this.scale})`;
		}
	}

	//canvas zoom out
	zoomOut(fitToWidth: boolean) {
		this.highlightPrevFocus();
		this.canvas.style.maxHeight = null;
		let newScale;
		newScale = fitToWidth ? Math.round(this.fitToWidthScale * 100) / 100 : Math.round((this.scale - 0.1) * 100) / 100;
		if (newScale >= AppConfig.IMAGE_MIN_SCALE) {
			this.scale = newScale;
			this.canvas.style.transform = '';
			this.canvas.style.transform = `scale(${this.scale})` + this.persistentCanvasStyle.transform;
			if (this.canvas.style.transform.indexOf('180') != -1) {
				this.canvas.style.transformOrigin = '-5% 65%';
				// this.canvas.style.transformOrigin = 'left bottom';
			} else {
				this.canvas.style.transformOrigin = 'top left';
			}
			this.persistentCanvasStyle.scale = `scale(${this.scale})`;
		}
		this.fitToWidthScale = 1;
	}

	//fit to width
	fitToWidth() {
		this.fitToWidthScale = 1;
		this.zoomOut(true);
	}

	//fit to height
	fitToHeight() {
		this.canvas.style.maxHeight = 'inherit';
		this.highlightPrevFocus();
	}

	//submit dynamic form
	async sendToProcess() {
		//dirty checking a form and showing dialog conditionally
		let formDirtyAndNull = Object.keys(this.child.form.controls).find((control) => {
			let field = this.fields.find(f => { return f.fieldCode == control });
			return field?.required && this.form.get(control)!.value == '';
		});
		if (formDirtyAndNull && !this.hideFormControls && !this.forceSend) {
			this.showSendDefaultValueDialog();
			return;
		}
		var currentField = {
			"type": '', "value": '', "left": '', "right": '', "top": '', "bottom": '', "width": '', "height": '', "fieldType": '',
			"fieldName": '', "capturedValue": '', 'page': ''
		};
		if (document.getElementsByClassName('hidden-form-control').length > 0) {
			Object.keys(this.child.form.controls).forEach(field => {
				const control = this.form.get(field);
				let htmlElement = document.getElementById(field);
				let fieldElement = this.fields.find(f => { return f.fieldCode == field });
				let isDateElem = false;
				if (htmlElement) {
					if (htmlElement.classList.contains("mat-datepicker-input")) {
						isDateElem = true;
					}
					currentField = {
						"type": field, "value": fieldElement!.defaultValue, "left": htmlElement!.getAttribute('data-x')!, "capturedValue": this.fieldsInitialValues.get(fieldElement!.label),
						"top": htmlElement!.getAttribute('data-y')!, "right": htmlElement!.getAttribute('data-width')! + htmlElement!.getAttribute('data-x')!,
						"bottom": htmlElement!.getAttribute('data-height')! + htmlElement!.getAttribute('data-y')!,
						"width": htmlElement!.getAttribute('data-width')!, "height": htmlElement!.getAttribute('data-height')!, "fieldType": fieldElement!.fieldTypeClassification, "fieldName": fieldElement!.label,
						"page": htmlElement!.getAttribute('data-page')!
					}
				} else {
					//handling of hidden fields
					let hiddenElem = this.fields.find((elem) => { return elem.fieldCode == field });

					currentField = {
						"type": field, "value": fieldElement!.defaultValue, "left": hiddenElem!.x.toString(), "capturedValue": this.fieldsInitialValues.get(fieldElement!.label),
						"top": hiddenElem!.y.toString(), "right": hiddenElem!.width.toString() + hiddenElem!.x.toString(),
						"bottom": hiddenElem!.height.toString() + hiddenElem!.y.toString(), "width": hiddenElem!.width.toString(),
						"height": hiddenElem!.height.toString(), "fieldType": fieldElement!.fieldTypeClassification,
						"fieldName": fieldElement!.label, "page": ''
					}
				}
				if (field == 'DOCUMENT_TYPE_VALUE') {
					currentField.value = control?.value;
					if (currentField.value != this.taskResponse.capturedFields.find((capt: any) => { return capt.type == "DOCUMENT_TYPE_VALUE" })?.value) {
						this.processTaskRequest.QFSFields.push(currentField);
					}
				}
				//parsing date to ISO format
				if (fieldElement!.controlType == "date") {
					let dateVal = fieldElement!.defaultValue;
					dateVal = moment.utc(dateVal);
					if (dateVal.isValid()) {
						dateVal.locale('en-US')
						currentField['value'] = dateVal.format(AppConfig.DATE_FORMATS.YYYYMMDD);
					}
				}
				//handling the decimal values
				if (htmlElement && htmlElement.classList.contains("decimal-field")) {
					let value = fieldElement!.defaultValue;
					if (value && !isNaN(value)) {
						currentField['value'] = Number(value).toFixed(2).toString()
					} else {
						currentField['value'] = '';
					}
				}
				if (currentField['value']) {
					currentField['value'] = currentField['value'].trim();
					if (currentField.type == 'SUPPLIER_VAT_NUMBER' && this.vatIdExtracted) {
						if (this.originalVATID != currentField['value']) {
							this.processTaskRequest.enrichDataModified = true;
						}
					}
					if (currentField.type == 'IBAN_NUMBER' && this.ibanExtracted) {
						if (this.originalIBAN) {
							this.processTaskRequest.enrichDataModified = true;
						}
					}
					this.processTaskRequest.currentFields.push(currentField);
				}
				isDateElem = false;
			});
		} else {
			if (this.child && this.child?.form) {
				this.child.validateForm();
				if (this.isFormValid()) {
					Object.keys(this.child.form.controls).forEach(field => {
						const control = this.child.form.get(field);
						let fieldElement = this.fields.find(f => { return f.fieldCode == field });
						let htmlElement = document.getElementById(field);
						let isDateElem = false;
						//force sending default values if mandatory validation fails
						let formVal;
						//below var is reqd to identify later whether value was cleared by user or wasn't there at all
						let userClearedFormVal = false;
						if (fieldElement?.required && control?.value == '') {
							formVal = fieldElement.defaultValue;
							userClearedFormVal = true;
						} else {
							formVal = control?.value;
						}
						if (htmlElement) {
							if (htmlElement.classList.contains("mat-datepicker-input")) {
								isDateElem = true;
							}
							currentField = {
								"type": field, "value": formVal, "left": htmlElement!.getAttribute('data-x')!, "capturedValue": this.fieldsInitialValues.get(fieldElement!.label),
								"top": htmlElement!.getAttribute('data-y')!, "right": htmlElement!.getAttribute('data-width')! + htmlElement!.getAttribute('data-x')!,
								"bottom": htmlElement!.getAttribute('data-height')! + htmlElement!.getAttribute('data-y')!,
								"width": htmlElement!.getAttribute('data-width')!, "height": htmlElement!.getAttribute('data-height')!,
								"fieldType": fieldElement!.fieldTypeClassification, "fieldName": fieldElement!.label,
								"page": htmlElement!.getAttribute('data-page')!
							}
						} else {
							//handling of hidden fields
							let hiddenElem = this.fields.find((elem) => { return elem.fieldCode == field });
							if (hiddenElem!.controlType == 'date') {
								isDateElem = true;
							}
							currentField = {
								"type": field, "value": formVal, "left": hiddenElem!.x.toString(), "capturedValue": this.fieldsInitialValues.get(fieldElement!.label),
								"right": hiddenElem!.width.toString() + hiddenElem!.x.toString(),
								"top": hiddenElem!.y.toString(), "bottom": hiddenElem!.height.toString() + hiddenElem!.y.toString(),
								"width": hiddenElem!.width.toString(), "height": hiddenElem!.height.toString(),
								"fieldType": fieldElement!.fieldTypeClassification, "fieldName": fieldElement!.label,
								"page": fieldElement!.page.toString()
							}
						}
						//parsing date to ISO format
						if (isDateElem) {
							//force sending default values if mandatory validation fails
							let dateVal;
							if (fieldElement?.required && control?.value == '') {
								dateVal = moment.utc(fieldElement.defaultValue).locale(
									AppConfig.USER_CULTURE || AppConfig.BROWSER_CULTURE
								);
								userClearedFormVal = true;
							} else {
								dateVal = control?.value;
							}
							let momentObj = moment(dateVal);
							if (momentObj.isValid()) {
								momentObj.locale('en-US')
								currentField['value'] = momentObj.format(AppConfig.DATE_FORMATS.YYYYMMDD);
							}
							//comparing original OCR mapped value(if any) to current one
							if (htmlElement?.getAttribute("data-orig-date") && htmlElement?.getAttribute("data-orig-date") != currentField['value']) {
								this.processTaskRequest.enrichDataModified = true;
							}
						}
						//handling the decimal values
						if (htmlElement && htmlElement.classList.contains("decimal-field")) {
							//force sending default values if mandatory validation fails
							let decimalVal;
							if (fieldElement?.required && control?.value == '') {
								decimalVal = GlobalizeService.formatNumber(fieldElement.defaultValue, AppConfig.USER_CULTURE);
								userClearedFormVal = true;
							} else {
								decimalVal = control?.value;
							}
							let value: any = GlobalizeService.unformatAnyNumber(decimalVal);
							if (value && !isNaN(value)) {
								currentField['value'] = Number(value).toFixed(2).toString()
							} else {
								currentField['value'] = '';
							}
						}
						if (currentField['value']) {
							currentField['value'] = currentField['value'].trim();
							if (currentField.type == 'SUPPLIER_VAT_NUMBER' && this.vatIdExtracted) {
								if (this.originalVATID != currentField['value']) {
									this.processTaskRequest.enrichDataModified = true;
								}
							}
							if (currentField.type == 'IBAN_NUMBER') {
								if (this.ibanExtracted && this.originalIBAN != currentField['value']) {
									this.processTaskRequest.enrichDataModified = true;
								}
								currentField['capturedValue'] = JSON.parse(currentField.capturedValue);
								currentField['value'] = JSON.parse(currentField.value);
								if (currentField['value'].length > 0) {
									this.processTaskRequest.currentFields.push(currentField);
								}
							}
							else if (currentField.type == 'BANK_ACCOUNT_VALUE') {
								currentField['capturedValue'] = JSON.parse(currentField.capturedValue);
								currentField['value'] = JSON.parse(currentField.value);
								if (currentField['value'].length > 0) {
									this.processTaskRequest.currentFields.push(currentField);
								}
							}
							else {
								this.processTaskRequest.currentFields.push(currentField);
							}
							//SPDF-700: sending the fields which have been add/modify/delete(ed)
							let currentFieldClone = JSON.parse(JSON.stringify(currentField));
							let capturedField = this.taskResponse.capturedFields.find((capt: any) => capt.type == currentFieldClone.type);
							let htmlElemByDataAttrib = document.querySelector(`[data-name="${currentFieldClone.type}"]`);
							/*Special handling for supplier number and name is reqd as both populate data from dialog */
							if (field == 'SUPPLIER_NUMBER' || field == 'SUPPLIER_NAME') {
								this.addDimensionsToTaskRequest(field, capturedField, currentFieldClone, userClearedFormVal);
							} else if (currentField.type == 'IBAN_NUMBER' && this.child?.form.get(currentField.type)?.dirty) {
								//restricting QFS for IBAN/BBANS until functionality is implemented at QFS processing level
								// this.processQFSFieldsForChipInput(currentField, currentFieldClone);
							} else if (currentField.type == 'BANK_ACCOUNT_VALUE' && this.child?.form.get(currentField.type)?.dirty) {
								//restricting QFS for IBAN/BBANS until functionality is implemented at QFS processing level
								// this.processQFSFieldsForChipInput(currentField, currentFieldClone);
							} else if (capturedField && currentFieldClone.value != capturedField.value && htmlElemByDataAttrib?.classList.contains('ng-dirty')) {
								/*Instance example:  captured data has 1222.4 but we parse it upto 2 decimal places to 1222.40, 
								 so in order to treat both as same, special handling for decimal fields is reqd*/
								if (htmlElemByDataAttrib?.classList.contains('decimal-field')) {
									if (!isNaN(Number(currentFieldClone.value)) && !isNaN(Number(capturedField.value))) {
										if (Number(currentFieldClone.value) != Number(capturedField.value)) {
											if (userClearedFormVal) {
												currentFieldClone.value = '';
											}
											this.processTaskRequest.QFSFields.push(currentFieldClone);
										}
									}
								} else {
									if (userClearedFormVal) {
										currentFieldClone.value = '';
									}
									this.processTaskRequest.QFSFields.push(currentFieldClone);
								}
							}
							else if (!capturedField) {
								// pushing only if user entered/modified value and not when default value is populated
								if (htmlElemByDataAttrib?.classList.contains('ng-dirty')) {
									if (userClearedFormVal) {
										currentFieldClone.value = '';
									}
									this.processTaskRequest.QFSFields.push(currentFieldClone);
								}
							}
							//end of fxnality
						}
						isDateElem = false;
					});
				} else {
					this.showErrorBanner = true;
					return;
				}
			} else {
				return;
			}
		}
		this.showErrorBanner = false;
		//handle address fields to be sent along
		await this.handleHiddenAddressFields(this.processTaskRequest.capturedFields);
		//handle LINE ITEMS to be sent along
		//don't send line items in case of non-invoice
		if (this.child.form.controls['DOCUMENT_TYPE_VALUE']?.value != '10004') {
			if (this.invoiceLineItems?.length > 0 || this._updatedInvoiceLineItems?.length > 0) {
				this.processTaskRequest.currentFields.push({ type: 'LINE_ITEM', fieldType: 'Standard', value: this._updatedInvoiceLineItems });
			}
			if (this.invoiceTaxData?.length > 0 || this._updatedInvoiceTaxData?.length > 0) {
				this.processTaskRequest.currentFields.push({ type: 'TOTAL_TAX_AMOUNT_VALUE_DETAIL', fieldType: 'Standard', value: this._updatedInvoiceTaxData });
			}
		}

		try {
			this.processingTask = true;
			this.child?.toggleFormState(this.processingTask);
			this.processTaskRequest.outputQueueArn = this.arnDetails.outputQueueArn;
			this.processTaskRequest.roleArn = this.arnDetails.roleArn;
			this.processTaskRequest.invoiceAddress = this.getTaskRequest.invoiceAddress;
			this.processTaskRequest.customerName = this.taskResponse.businessUnit;
			this.processTaskRequest.companyCode = this.taskResponse.unitIdentifier;
			let data: any = await this.apiService.SendTaskToProcess(this.processTaskRequest);
			this.canvasParent.scrollTop = 0;
			this.openSnackBar(this.tr('spdf.app.snackbar.message.sendToProcess'), '', 'Success');
			this.child?.toggleFormState(this.processingTask);
			if (data) {
				this.ocrData = [];
				this.resetGridDataVariables();
				this.prepareTaskResponse(data);
				await this.globalize.registerLocale(
					AppConfig.USER_CULTURE || AppConfig.BROWSER_CULTURE);
				this.isImageContentLoading = true;
				await this.prepareFormAndCanvas();
				this.rotate('');
				this.isImageContentLoading = false;
				this.processingTask = false;
				//appending bumId to query params
				const queryParams = {
					bumId: data.bumId
				};
				// Navigating to the same route with appended query parameters
				this.router.navigate([], {
					relativeTo: this.activatedRoute,
					queryParams,
					queryParamsHandling: 'merge', // Use 'merge' to append to existing query parameters
				});
				//triggering the validation so Warnings appear(if any)
				this.child.validateForm();
			}
		} catch (err: any) {
			if (err.status == 400) {
				this.processTaskRequest.QFSFields = [] as any
				this.isImageContentLoading = false;
				this.processingTask = false;
				this.openSnackBar(this.tr('spdf.app.snackbar.message.sendToProcess.failed'), '', 'Error');
			}
		}
	}

	addDimensionsToTaskRequest(field: string, capturedField: any, currentFieldClone: any, userClearedFormVal: boolean) {
		let key = (field == 'SUPPLIER_NUMBER') ? "supplierNumber" : "supplierName";
		if (userClearedFormVal) {
			// when user do not provide vendor info, it should NOT use default values as dimensions 
			currentFieldClone.value = '';
		}
		if (currentFieldClone.value != '')
			this.processTaskRequest.dimensions[key] = currentFieldClone.value;
	}

	showSendDefaultValueDialog() {
		const dialogRef = this.dialog.open(SendDefaultValuesComponent, {
			width: '480px',
			data: {
				messageKey1: 'spdf.app.module.bu.dialog.sendDefault.description.para1',
				messageKey2: 'spdf.app.module.bu.dialog.sendDefault.description.para2',
				messageKey3: 'spdf.app.module.bu.dialog.sendDefault.description.para3'
			}
		});
		dialogRef.afterClosed().subscribe(async (result) => {
			if (result && result.action == 'default') {
				this.forceSend = true;
				await this.sendToProcess();
				this.forceSend = false;
			}
		});
	}
	onCancel() {
		this.close.emit(null);
	}

	//get transformed coordinates w.r.t. angle rotation
	getTransformedCoords(oldX: any, oldY: any, angleInRad: any) {
		var angle = (angleInRad * -1);
		var x2 = oldX;
		var y2 = oldY;
		var cos = Math.cos(angle);
		var sin = Math.sin(angle);
		var newx = Math.floor(x2 * cos - y2 * sin);
		var newy = Math.floor(x2 * sin + y2 * cos);
		return { x: newx, y: newy };
	}

	//convert x, y coordinates to canvas coordinates to handle scaling
	toCanvasCoords(pageX: any, pageY: any, scale: any) {
		pageX += this.canvasParent.scrollLeft;
		pageY += this.canvasParent.scrollTop;
		const canvasX = (pageX - this.canvas.offsetLeft) * this.canvas.width / this.canvas.clientWidth;
		const canvasY = (pageY - this.canvas.offsetTop) * this.canvas.height / this.canvas.clientHeight;
		return { x: canvasX / scale, y: canvasY / scale };
	}

	toPageCoords(canvasX: any, canvasY: any, scale: any) {
		// Get the canvas scale
		const scaleX = this.canvas.width / this.canvas.offsetWidth;
		const scaleY = this.canvas.height / this.canvas.offsetHeight;
		// Get the mouse position relative to the canvas
		const mouseX = canvasX - this.canvas.offsetLeft;
		const mouseY = canvasY - this.canvas.offsetTop;
		// Convert the canvas coordinates to page coordinates
		const pageX = (mouseX * scaleX) * scale;
		const pageY = (mouseY * scaleY) * scale;
		return { x: pageX, y: pageY };
	}

	//clear text input
	clear(e: any) {
		if (e.fieldType == 'date') {
			this.form.get(e.fieldCode)!.setValue('');
		} else {
			if (e.fieldCode == 'IBAN_NUMBER' || e.fieldCode == 'BANK_ACCOUNT_VALUE') {
				e.value = [];
				this.form.get(e.fieldCode)!.setValue('[]');
			} else {
				e.value = '';
			}
		}
		this.form.get(e.fieldCode)!.markAsDirty();
		this.clearValidationError(this.form.get(e.fieldCode)!);
		document.getElementById(e.fieldCode)?.setAttribute('data-x', '0');
		document.getElementById(e.fieldCode)?.setAttribute('data-y', '0');
		document.getElementById(e.fieldCode)?.setAttribute('data-width', '0');
		document.getElementById(e.fieldCode)?.setAttribute('data-height', '0');
		document.getElementById(e.fieldCode)?.setAttribute('data-page', '0');
		this.canvasInit();
	}

	//get task info
	async getTaskInfo(request: any) {
		try {
			let data: any = await this.apiService.GetTask(request);
			this.arnDetails.outputQueueArn = data.outputQueueArn;
			this.arnDetails.roleArn = data.roleArn;
			this.isContentLoading = true;
			this.prepareTaskResponse(data);
			await this.globalize.registerLocale(
				AppConfig.USER_CULTURE || AppConfig.BROWSER_CULTURE);
			this.isContentLoading = false;
			//appending bumId to query params
			const queryParams = {
				bumId: data.bumId
			};
			// Navigating to the same route with appended query parameters
			this.router.navigate([], {
				relativeTo: this.activatedRoute,
				queryParams,
				queryParamsHandling: 'merge', // Use 'merge' to append to existing query parameters
			});
		} catch (err) {
			//handle error
		}
	}

	//get ocr data w.r.t. bumId
	async getOcrData(request: any) {
		try {
			this.ocrData = [];
			this.pageAngles = [];
			let data: any = await this.apiService.GetOcrData(request);
			if (data) {
				let parsedOcrData;
				parsedOcrData = data instanceof Object ? data : JSON.parse(data);
				for (var i = 0; i < parsedOcrData.ocr.length; i++) {
					let dataField = {} as any;
					dataField.string = parsedOcrData.ocr[i].hasValue ? parsedOcrData.ocr[i].hasValue : parsedOcrData.ocr[i].Text;
					if (parsedOcrData.ocr[i].hasIBAN) {
						dataField.hasIBAN = parsedOcrData.ocr[i].hasIBAN || parsedOcrData.ocr[i].hasValue || parsedOcrData.ocr[i].Text;
						this.originalIBAN = parsedOcrData.ocr[i].hasIBAN || '';
					}
					if (parsedOcrData.ocr[i].hasVATID) {
						dataField.hasVATID = parsedOcrData.ocr[i].hasVATID || parsedOcrData.ocr[i].hasValue || parsedOcrData.ocr[i].Text;
						this.originalVATID = parsedOcrData.ocr[i].hasVATID || '';
					}
					if (parsedOcrData.ocr[i].hasDate) {
						dataField.hasDate = parsedOcrData.ocr[i].hasDate || parsedOcrData.ocr[i].hasValue || parsedOcrData.ocr[i].Text;
					}
					dataField.x = parsedOcrData.ocr[i].cl;
					dataField.x = parsedOcrData.ocr[i].cl;
					dataField.y = parsedOcrData.ocr[i].ct;
					dataField.width = parsedOcrData.ocr[i].cr - parsedOcrData.ocr[i].cl;
					dataField.height = parsedOcrData.ocr[i].cb - parsedOcrData.ocr[i].ct;
					dataField.page = parsedOcrData.ocr[i].pn;
					this.ocrData.push(dataField);
					this.savePageAnglesFromOcr(parsedOcrData.ocr[i].pn, parsedOcrData.ocr[i].ang)
				}
			}
		} catch (err) {
			//handle error
		}
	}

	savePageAnglesFromOcr(ocrPn: any, ocrAng: any) {
		let currentpage = this.pageAngles.find(p => p.pn == ocrPn)
		if (!currentpage) {
			this.pageAngles.push({ pn: ocrPn, ang: -ocrAng })
		}
	}

	//open unsaved data warning dialog
	async checkDirtySkipTask(nextTask: boolean, forceSkip: boolean) {
		if (this.child && this.child.form && this.child.form.dirty && !forceSkip) {
			const dialogRef = this.dialog.open(UnsavedDataWarningComponent, {
				width: '480px',
				data: {
					messageKey: 'spdf.app.module.document.validation.unsavedData.message'
				}
			});

			dialogRef.afterClosed().subscribe(async (results) => {
				if (results.ok === true) {
					if (results.action == 'discard') {
						await this.skipTask(nextTask);
					}
				}
			});
		} else {
			await this.skipTask(nextTask);
		}
	}

	// skip document
	async skipTask(nextTask: boolean) {
		this.skippingTask = true;
		//handle undefined receipt handle in case of empty queue
		if (this.processTaskRequest && !this.processTaskRequest.receiptHandle) {
			return;
		}
		this.child?.toggleFormState(this.skippingTask);
		try {
			this.skipTaskRequest.nextTask = nextTask;
			this.skipTaskRequest.invoiceAddress = this.getTaskRequest.invoiceAddress;
			let data: any = await this.apiService.SkipTask(this.skipTaskRequest);
			this.canvasParent.scrollTop = 0;
			if (this.showSkipSnackbar) {
				this.openSnackBar(this.tr('spdf.app.snackbar.message.documentSkipped'), '', 'Success');
			}
			this.showErrorBanner = false;
			this.child?.toggleFormState(this.skippingTask);
			if (nextTask) {
				this.ocrData = [];
				this.resetGridDataVariables();
				this.prepareTaskResponse(data);
				await this.globalize.registerLocale(
					AppConfig.USER_CULTURE || AppConfig.BROWSER_CULTURE);
				this.isImageContentLoading = true;
				await this.prepareFormAndCanvas();
				this.rotate('');
				this.isImageContentLoading = false;
				this.skippingTask = false;
				//appending bumId to query params
				const queryParams = {
					bumId: data.bumId
				};
				// Navigating to the same route with appended query parameters
				this.router.navigate([], {
					relativeTo: this.activatedRoute,
					queryParams,
					queryParamsHandling: 'merge', // Use 'merge' to append to existing query parameters
				});
				//triggering the validation so Warnings appear(if any)
				this.child.validateForm();
			}
		} catch (err) {
			//handle error
		}
	}

	//post fetch task operations
	prepareTaskResponse(data: any) {
		if (data) {
			this.getOcrDataRequest.ocrUrl = data.ocrUrl;
			this.taskResponse = data;
			if (data.empty) {
				this.taskResponse.allDocsValidated = true;
			} else {
				this.taskResponse.allDocsValidated = false;
			}
			this.commonService.setVendorUrl(data.vendorUrl);
			this.taskResponse['businessUnit'] = sessionStorage.getItem('businessUnit');
			this.taskResponse['unitIdentifier'] = sessionStorage.getItem('unitIdentifier');
			this.taskResponse['invoiceAddress'] = this.getTaskRequest.invoiceAddress;
			this.skipTaskRequest = this.taskResponse;
			this.skipTaskRequest.configurationId = data.configurationId;
			this.skipTaskRequest.receiptHandle = data.receiptHandle;
			this.skipTaskRequest.messageId = data.messageId;
			this.skipTaskRequest.receivingEmail = data.receivingEmail;
			this.skipTaskRequest.unitUuid = this.getTaskRequest.unitUuid;
			this.processTaskRequest.bumId = data.bumId;
			this.processTaskRequest.unitUuid = this.getTaskRequest.unitUuid;
			this.processTaskRequest.receiptHandle = data.receiptHandle;
			this.processTaskRequest.messageId = data.messageId;
			this.processTaskRequest.configurationId = data.configurationId;
			this.processTaskRequest.receivingEmail = data.receivingEmail;
			this.processTaskRequest.capturedFields = data.capturedFields;
			let ibanIdx = 0;
			let bbanIdx = 0;
			if (this.processTaskRequest.capturedFields) {
				// type is required to be set for exact key based comparison to handle chip inputs
				this.taskResponse.capturedFields = this.taskResponse.capturedFields.map((obj: any) => {
					if (obj.type == 'IBAN_NUMBER') {
						ibanIdx++;
						return { ...obj, key: obj.type + ibanIdx };
					} else if (obj.type == 'BANK_ACCOUNT_VALUE') {
						bbanIdx++;
						return { ...obj, key: obj.type + bbanIdx };
					} else {
						return obj;
					}
				});
			}
			this.processTaskRequest.currentFields = [] as any
			this.processTaskRequest.QFSFields = [] as any
			this.processTaskRequest.dimensions = {} as any
			this.ocrImageList = [];
			data.invoiceSignedImageRef.forEach((element: any) => {
				this.ocrImageList.push(element.signedUrl)
			});
			this.configFields = data.configurationFields;
			let configDocType = this.configFields.find((conf: any) => { return conf.fieldCode == "DOCUMENT_TYPE_VALUE" });
			this.taxBreakdownInConfigurationFields = this.configFields.find((conf: any) => { return conf.fieldCode == "TOTAL_TAX_AMOUNT_VALUE_DETAIL" });
			this.docType = configDocType.defaultValue;
			this.hideFormControls = true ? configDocType.defaultValue == '10004' : false;
			for (var i = 0; i < data.capturedFields.length; i++) {
				if (data.capturedFields[i].value) {
					if (data.capturedFields[i].type == 'LINE_ITEM') {
						this.invoiceLineItems = this.handleNullValues(AppUtils.deepCopyObject(data.capturedFields[i].value));
						this._updatedInvoiceLineItems = [...this.invoiceLineItems];
						continue;
					}
					if (data.capturedFields[i].type == 'TOTAL_TAX_AMOUNT_VALUE_DETAIL') {
						this.invoiceTaxData = this.handleNullValues(AppUtils.deepCopyObject(data.capturedFields[i].value));
						this._updatedInvoiceTaxData = AppUtils.deepCopyObject(this.invoiceTaxData);
						continue;
					}
					if (data.capturedFields[i].type == 'SUPPLIER_COUNTRY_CODE') {
						if (data.capturedFields[i].value) {
							let cult: any;
							try {
								cult = countryLocaleMap.getLocaleByAlpha2(
									data.capturedFields[i].value.toString().toUpperCase());
								cult = cult?.replace('_', '-')
							} catch (err) {
								cult = ''
							}
							AppConfig.INVOICE_CULTURE = cult!;
						}
					}
					if (data.capturedFields[i].type == 'DOCUMENT_TYPE_VALUE') {
						this.docType = data.capturedFields[i].value;
						this.hideFormControls = true ? data.capturedFields[i].value == '10004' : false;
					}
					let dataField = {} as any;
					//using name instead of creating a new field called type, as its reqd in forms
					dataField.name = data.capturedFields[i].type;
					dataField.string = data.capturedFields[i].value;
					dataField.x = data.capturedFields[i].x;
					dataField.y = data.capturedFields[i].y;
					dataField.width = data.capturedFields[i].width;
					dataField.height = data.capturedFields[i].height;
					dataField.page = data.capturedFields[i].page;
					this.ocrData.push(dataField);
				}
			}
			this.taskResponse['isLineItemsActive'] = this.isLineItemsActive(this.docType);
			this.taskResponse['isTaxBreakdownActive'] = this.isTaxBreakdownActive(this.docType);
		}
	}

	isLineItemsActive(documentType: string): boolean {
		return sessionStorage.getItem('isLineItemsActive') == "true" && environment.invoiceLinesFeatureFlag && documentType != '10004';
	}

	isTaxBreakdownActive(documentType: string): boolean {
		return this.taskResponse['isTaxBreakdownActive'] = (this.taxBreakdownInConfigurationFields ? true : false) && environment.taxBreakdownFeatureFlag && documentType != '10004';
	}

	handleNullValues(data: any[][]) {
		let _data: any[] = [];
		data.forEach(item => {
			let _item: any[] = [];
			item.forEach(obj => {
				if (obj.value) {
					_item.push(obj)
				}
			})
			_data.push(_item);
		})
		return _data;
	}

	//get filtered visible fields 
	getVisibleFields() {
		return this.fields.filter(f => f.hidden == false).map(elem => {
			if (elem.fieldType == 'decimal') {
				if (elem.value) {
					elem.value = GlobalizeService.formatNumber(elem.value, AppConfig.USER_CULTURE);
				}
			}
			if (elem.fieldCode == 'DOCUMENT_TYPE_VALUE' && elem.value == '10004') {
				this.hideFormControls = true;
			}
			if (this.taxesAndSumsFields.indexOf(elem.fieldCode) !== -1) {
				elem["taxesAndSumsSection"] = true;
			}
			return elem
		});
	}

	openSnackBar(message: any, action: string, snackType: any) {
		const _snackType = snackType !== undefined ? snackType : 'Info';
		this.snackBar.openFromComponent(IconSnackBarComponent, {
			data: { message: message, snackType: _snackType, action: action },
			panelClass: 'gt-snackbar',
			duration: AppConfig.SNACKBAR_DURATION_IN_SECONDS * 1000
		});
	}


	reloadPage() {
		location.reload();
	}

	isValidDate(date: any) {
		return date instanceof Date;
	}

	//scroll to a particular position from top
	scrollToPosition(top: number, left: number) {
		if (top == 0 || left == 0) {
			return;
		}
		// scale the canvas coordinates to container coordinates
		const containerX = left * this.scale;
		const containerY = top * this.scale;
		let originalScrollRatioX = containerX / this.canvas.width;
		let originalScrollRatioY = containerY / this.canvas.height;
		//10 is the additional space added on top for better visibility, CONFIGURABLE as per req.
		let scrollLeft = originalScrollRatioX * this.canvasParent.scrollWidth - 10;
		let scrollTop = originalScrollRatioY * this.canvasParent.scrollHeight - 10;
		// set the scrollTop property of the container to scroll to the desired point
		this.canvasParent.scrollTo({
			top: scrollTop,
			left: scrollLeft,
			behavior: "smooth" // optional, adds smooth scrolling effect
		});
	}

	//download invoice
	downloadInvoice() {
		this.highlightPrevFocus();
		try {
			this.apiService.DownloadInvoice(this.taskResponse.pdfUrl);
		} catch (err) {
			//handle error
		}
	}

	//go back to homepage
	goBack() {
		if (this.child && this.child.form && this.child.form.dirty) {
			const dialogRef = this.dialog.open(UnsavedDataWarningComponent, {
				width: '480px',
				data: {
					messageKey: 'spdf.app.module.document.validation.unsavedData.message'
				}
			});
			dialogRef.afterClosed().subscribe(async (results) => {
				if (results.ok === true) {
					if (results.action == 'discard') {
						this.router.navigate(['']);
					}
				}
			});
		} else {
			this.router.navigate(['']);
		}
	}

	//On document type change hide all form fields
	documentTypeChanged(event: any) {
		this.hideFormControls = true ? event.value == '10004' : false;
		if (this.hideFormControls) {
			this.isLineItemsValidPrevState = new Boolean(this.isLineItemsValid).valueOf();
			this._isLineItemsValid(true);
			this.isTaxDataValidPrevState = new Boolean(this.isTaxDataValid).valueOf();
			this._isTaxDataValid(true);
		} else {
			this.invoiceLineItems = this._updatedInvoiceLineItems;
			this._isLineItemsValid(this.isLineItemsValidPrevState);
			this.adjustSplitAreaSizes(); // position of invoice lines split area 

			this.invoiceTaxData = this._updatedInvoiceTaxData;
			this._isLineItemsValid(this.isTaxDataValidPrevState);
		}
		this.hideFormControlsOnUI = this.hideFormControls;
		this.taskResponse['isLineItemsActive'] = this.isLineItemsActive(event.value);
		this.taskResponse['isTaxBreakdownActive'] = this.isTaxBreakdownActive(event.value);
		this.canvasInit();
	}

	chipSelectionChanged(event: any) {
		this.selectedChip = event;
	}

	//open email details page
	async getEmailDetails() {
		// let request = {
		// 	"bumId": this.taskResponse.bumId
		// }
		let emailRes: any = await this.apiService.GetEmailData(this.taskResponse.bumId);
		const dialogRef = this.dialog.open(EmailDetailsComponent, {
			width: '480px',
			data: { emailData: emailRes }
		});
	}

	//check if the form is valid without errors(proceed if there are warnings)
	isFormValid() {
		if (this.child && this.child.form) {
			let totalErrors = 0;
			Object.keys(this.child.form.controls).forEach(field => {
				if (this.child.form.controls[field].errors) {
					// ignore if error is of type: 'warning'
					if (!(this.child.form.controls[field].errors!['requiredWarning'] || this.child.form.controls[field].errors!['validationError']) || this.child.form.controls[field].errors!['matDatepickerParse']) {
						totalErrors++;
					}
				}
			});
			return totalErrors == 0;
		}
	}

	//add hidden supplier/customer fields to current fields
	async handleHiddenAddressFields(capturedFields: any) {
		let addressFields = [];
		for (let i = 0; i < capturedFields.length; i++) {
			if (AppConfig.ADDRESS_HIDDEN_FIELDS.includes(capturedFields[i].type)) {
				let field = {
					type: capturedFields[i].type,
					value: capturedFields[i].value,
					capturedValue: capturedFields[i].value,
					left: capturedFields[i].x || '',
					right: capturedFields[i].right || '',
					top: capturedFields[i].y || '',
					bottom: capturedFields[i].bottom || '',
					width: capturedFields[i].width || '',
					height: capturedFields[i].height || '',
					fieldType: 'Standard',
					page: capturedFields[i].page || '',
				};
				addressFields.push(field);
			}
		}
		if (addressFields.length > 0) {
			this.processTaskRequest.currentFields = this.processTaskRequest.currentFields.concat(addressFields);
		}
	}


	_updatedInvoiceLineItems: any;
	isLineItemsValid: boolean = true;
	//Previous state is reqd to switch back to old validation state on document type change
	isLineItemsValidPrevState: boolean = true;
	updatedInvoiceLineItems(e: any) {
		this._updatedInvoiceLineItems = e;
		// console.log(this.invoiceLineItems, this._updatedInvoiceLineItems);
	}
	_isLineItemsValid(e: any) {
		this.isLineItemsValid = e
	}

	_updatedInvoiceTaxData: any;
	isTaxDataValid: boolean = true;
	//Previous state is reqd to switch back to old validation state on document type change
	isTaxDataValidPrevState: boolean = true;
	updatedInvoiceTaxData(e: any) {
		this._updatedInvoiceTaxData = e;
		// console.log(this.invoiceTaxData, this._updatedInvoiceTaxData);
	}
	_isTaxDataValid(e: any) {
		this.isTaxDataValid = e
	}

	dragEnd(e: any) {
		sessionStorage.setItem('splitAreaSizes', JSON.stringify(e["sizes"]))
	}

	processQFSFieldsForChipInput(currentField: any, currentFieldClone: any) {
		let capturedChipFields = this.taskResponse.capturedFields.filter((capt: any) => capt.type == currentFieldClone.type);
		let currentFieldValues = currentFieldClone.value;
		for (let c = 0; c < capturedChipFields.length; c++) {
			//find the match of captured chip in current field values based on key
			let match = currentFieldClone.value.find((val: any) => {
				return val.key == capturedChipFields[c].key
			});
			let currentFieldCloned: any = {};
			// if no match found, means chip deleted
			if (!match) {
				currentFieldCloned.capturedValue = capturedChipFields[c].value;
				currentFieldCloned.fieldName = currentFieldClone.fieldName;
				currentFieldCloned.fieldType = currentFieldClone.fieldType;
				currentFieldCloned.value = 0;
				currentFieldCloned.width = 0;
				currentFieldCloned.height = 0;
				currentFieldCloned.page = 0;
				currentFieldCloned.x = 0;
				currentFieldCloned.y = 0;
				this.processTaskRequest.QFSFields.push(currentFieldCloned);
			} else {
				//if match found, compare the values
				if (match.value != capturedChipFields[c].value) {
					currentFieldValues[c].capturedValue = capturedChipFields[c].value;
					this.processTaskRequest.QFSFields.push(currentFieldValues[c]);
				}
			}
		}
	}

	//handle chip input mapping via document selection 
	handleChipMappings(extractedValue: any, ocrData: any, ibanFields: any, bbanFields: any, controlName: any, chipMaxLength: any) {
		if (controlName == 'BANK_ACCOUNT_VALUE') {
			//remove all leading colons 
			extractedValue = ocrData.string.toString().trim().replace(/^:+/, '');
			if (this.prevFocus) {
				this.prevFocus.value = extractedValue;
			}
			bbanFields = JSON.parse(this.form.controls[controlName].value);
			let selectedChipField: any = {};
			if (this.selectedChip) {
				selectedChipField = bbanFields.find((f: any) => f.key == this.selectedChip.key);
			} else {
				this.child?.addChip(this.prevFocus, bbanFields, controlName);
				selectedChipField = {};
			}
			extractedValue = extractedValue.slice(0, chipMaxLength);
			let indexOfChip = bbanFields.indexOf(selectedChipField);
			selectedChipField['x'] = ocrData.x.toString();
			selectedChipField['y'] = ocrData.y.toString();
			selectedChipField['width'] = ocrData.width.toString();
			selectedChipField['height'] = ocrData.height.toString();
			selectedChipField['value'] = extractedValue;
			selectedChipField['page'] = this.paginator.page.toString();
			if (indexOfChip != -1) {
				bbanFields[indexOfChip] = selectedChipField;
			} else {
				Object.assign(bbanFields[bbanFields.length - 1], selectedChipField);
			}
			this.form.controls[controlName].setValue(JSON.stringify(bbanFields));
			this.child?.form.controls[controlName].markAsDirty();
			this.selectedChip = null;
			document.getElementById('bban-textbox')?.setAttribute('value', '');
		} else {
			this.ibanExtracted = true;
			//remove all leading colons 
			extractedValue = (ocrData.hasIBAN || ocrData.string).toString().replace(/^:+/, '');
			if (this.prevFocus) {
				this.prevFocus.value = extractedValue;
			}
			ibanFields = JSON.parse(this.form.controls[controlName].value);
			let selectedChipField: any = {};
			if (this.selectedChip) {
				selectedChipField = ibanFields.find((f: any) => f.key == this.selectedChip.key);
			} else {
				this.child?.addChip(this.prevFocus, ibanFields, controlName);
				selectedChipField = {};
			}
			extractedValue = extractedValue.slice(0, chipMaxLength);
			let indexOfChip = ibanFields.indexOf(selectedChipField);
			selectedChipField['x'] = ocrData.x.toString();
			selectedChipField['y'] = ocrData.y.toString();
			selectedChipField['width'] = ocrData.width.toString();
			selectedChipField['height'] = ocrData.height.toString();
			selectedChipField['value'] = extractedValue;
			selectedChipField['page'] = this.paginator.page.toString();
			if (indexOfChip != -1) {
				ibanFields[indexOfChip] = selectedChipField;
			} else {
				Object.assign(ibanFields[ibanFields.length - 1], selectedChipField);
			}
			this.form.controls[controlName].setValue(JSON.stringify(ibanFields));
			this.child?.form.controls[controlName].markAsDirty();
			this.selectedChip = null;
			document.getElementById('iban-textbox')?.setAttribute('value', '');
		}
	}

	clearChipFocus(evt: any) {
		this.selectedChip = null;
	}

	resetGridDataVariables() {
		this.invoiceLineItems = [];
		this._updatedInvoiceLineItems = [];
		this.isLineItemsValid = true;

		this.invoiceTaxData = [];
		this._updatedInvoiceTaxData = [];
		this.isTaxDataValid = true;
	}
}
