<div mat-dialog-title>
    <div>{{title | translate}}</div>
    <div class="gt-dialog-close">
        <button mat-icon-button color="primary" aria-label="{{'spdf.app.tooltips.close' | translate}}"
            matTooltip="{{'spdf.app.tooltips.close' | translate}}" mat-dialog-close>
            <mat-icon fontSet="far" fontIcon="fa-times"> </mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content>
    <p>
        {{message | translate}}
    </p>
</mat-dialog-content>

<div mat-dialog-actions>
    <button mat-stroked-button color="primary" mat-dialog-close
        attr.aria-label="{{'spdf.app.module.buup.cancel' | translate}}">
        {{cancelButtonText | translate}}
    </button>
    <button mat-flat-button color="primary" attr.aria-label="{{'spdf.app.module.buup.save' | translate}}"
        (click)="ok()">
        {{confirmButtonText | translate}}
    </button>
</div>