import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DecimalFields, fromGridModel, TaskResponse, TaxData, toGridModel } from './taxes-and-sums.model';
import { GlobalizeService } from 'src/app/app-services/globalize.service';
import { AppConfig } from 'src/app/app.config';
import { LocalizationService } from 'src/app/app-services/localization.service';
import { BaseTranslationService } from 'src/app/app-services/base.translation.service';
import { AppUtils } from '../app-util.service';

@Component({
  selector: 'taxes-and-sums',
  templateUrl: './taxes-and-sums.component.html',
  styleUrls: ['./taxes-and-sums.component.scss']
})
export class TaxesAndSumsComponent extends BaseTranslationService implements OnInit {


  @Input() taxData: any[][];
  @Input() showWarning: boolean = false;
  @Input() isDisabled: boolean = true;
  @Output() updatedTaxData = new EventEmitter<any>();
  @Output() isTaxDataValid = new EventEmitter<any>();
  @Output() readFocusOnCanvas = new EventEmitter<any>();

  _taxData: TaxData[] = [];
  isTaxDataValid$: boolean = true;
  reloadGrid: boolean = false;

  constructor(localization: LocalizationService) {
    super(localization);
  }
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ("taxData" in changes) {
      this._taxData = [];
      this.isTaxDataValid$ = true;
      this.mapToInvoiceDataModel([...this.taxData]);
      this.reloadGrid = !this.reloadGrid;
    }
  }

  addTaxData() {
    this._taxData = [new TaxData(this._taxData.length + 1)]
  }

  updatedRowData(e: any) {
    this._taxData = e;
    this.updatedTaxData.emit(this.mapToTaskResponseTaxData(e));
  }

  _isTaxDataValid(e: any) {
    this.isTaxDataValid$ = e;
    this.isTaxDataValid.emit(e);
  }

  _readFocusOnCanvas(e: any) {
    this.readFocusOnCanvas.emit(e);
  }

  mapToInvoiceDataModel(items: any[]) {
    items.forEach((_item: any[], index) => {
      let data: any = {};
      data["rowNumber"] = index + 1;
      _item.forEach(item => {
        let key = toGridModel[item["type"]];
        if (key) {
          if (DecimalFields.includes(key) && !isNaN(Number(item["value"]))) {
            data[key] = { value: AppUtils.localizeDecimal(item["value"]), data: item }
          } else {
            data[key] = { value: item["value"].toString(), data: item }
          }
        }
      })
      this._taxData.push(data);
    })
  }

  mapToTaskResponseTaxData(gridItems: TaxData[]) {
    let _updatedItems: any[][] = []
    gridItems.forEach((gridData: any) => {
      let _existingData: TaskResponse[] = this.taxData[gridData.rowNumber - 1];
      let new_item: any[] = [];
      if (_existingData) {
        // update field value of existing rows 
        new_item = this.updateValueInExistingFieldData(_existingData, gridData);
      } else {
        // insert new rows from grid to response json
        Object.keys(gridData).forEach(key => {
          if (key != 'rowNumber' && gridData[key]['value'].length > 0) {
            let item: any = {};
            item["type"] = fromGridModel[key];
            item["value"] = gridData[key]['value'];
            this.checkAndExtractDecimalValue(item, key, gridData[key]['value'])
            new_item.push(item);
          }
        })
      }
      if (new_item.length > 0) {
        _updatedItems.push(new_item);
      }
    })
    return _updatedItems;
  }

  checkAndExtractDecimalValue(item: any, key: string, value: string) {
    if (DecimalFields.includes(key)) {
      let extractedNumber = GlobalizeService.unformatNumber(value, AppConfig.USER_CULTURE);
      if (!isNaN(Number(extractedNumber))) {
        item["value"] = Number(extractedNumber);
      }
    }
  }

  updateValueInExistingFieldData(_existingData: TaskResponse[], gridData: any) {
    let new_item: any[] = [];
    _existingData.forEach(field => {
      let gridKey = toGridModel[field.type]
      if (!gridKey) {
        new_item.push(field); //insert extra field as it is
      }
      if (gridKey && gridData[gridKey]['value'].length > 0) {
        // add grid column to json only if value is not 
        field.value = gridData[gridKey]['value'];
        this.checkAndExtractDecimalValue(field, gridKey, gridData[gridKey]['value'])
        new_item.push(field);
      }
    })
    return new_item;
  }
}

