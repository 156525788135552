<div id="dynamic-form" class="doc-container">
    <mat-toolbar class="gt-titlebar">
        <div class="gt-titlebar-back-button">
            <button mat-icon-button color="primary" (click)="goBack()"
                matTooltip="{{'spdf.app.tooltips.goBack' | translate}}"
                attr.aria-label="{{'spdf.app.tooltips.goBack' | translate}}">
                <mat-icon fontSet="far" fontIcon="fa-arrow-left"></mat-icon>
            </button>
        </div>
        <div class="gt-titlebar-content-wrapper">
            <div class="gt-titlebar-left-content-wrapper">
                <div class="gt-titlebar-main-title mat-typography">
                    <h1>{{taskResponse?.businessUnit}}</h1>
                </div>
                <div class="gt-titlebar-subtitle-wrapper">
                    <span class="gt-titlebar-subtitle-content mat-body-1">
                        <span class="gt-titlebar-subtitle">{{taskResponse?.receivingEmail}}</span>
                    </span>
                    <mat-divider [vertical]="true" role="separator"></mat-divider>
                    <span class="gt-titlebar-subtitle-content mat-body-1">
                        <span class="gt-titlebar-subtitle">{{taskResponse?.invoiceAddress}}</span>
                    </span>
                    <mat-divider [vertical]="true" role="separator"></mat-divider>
                    <span [ngClass]="{'gt-badge-muted': taskResponse?.messageCount==0 }"
                        class="gt-badge">{{taskResponse?.messageCount}}</span>&nbsp;
                    <span class="gt-titlebar-subtitle-content mat-body-1">
                        <span class="gt-titlebar-subtitle">{{"spdf.app.module.bu.validation.header.docsInQueue" |
                            translate}}</span>
                    </span>
                </div>
            </div>
            <div class="gt-titlebar-right-content-wrapper" *ngIf="!taskResponse?.allDocsValidated">
                <button mat-stroked-button color="primary"
                    [disabled]="processingTask || skippingTask || isContentLoading" class="gt-progress-button"
                    (click)="checkDirtySkipTask(true,false)">
                    <div *ngIf="skippingTask">
                        <span>{{"spdf.app.actions.skip" | translate}}</span>
                        <div>
                            <mat-progress-spinner [diameter]="spinnerDiameter" [mode]="mode">
                            </mat-progress-spinner>
                        </div>
                    </div>
                    <span *ngIf="!skippingTask">{{"spdf.app.actions.skip" | translate}}</span>
                </button>
                <button mat-flat-button color="primary"
                    [disabled]="processingTask || skippingTask || isContentLoading || (!isFormValid()|| !isLineItemsValid || !isTaxDataValid)"
                    class="gt-progress-button" (click)="sendToProcess()">
                    <div *ngIf="processingTask">
                        <span>{{"spdf.app.actions.sendToProcess" | translate}} </span>
                        <div>
                            <mat-progress-spinner [diameter]="spinnerDiameter" [mode]="mode">
                            </mat-progress-spinner>
                        </div>
                    </div>
                    <span *ngIf="!processingTask">{{"spdf.app.actions.sendToProcess" | translate}}</span>
                </button>
                <!-- hidden as part of https://jira.basware.com/browse/SPDF-242-->
                <button mat-icon-button color="primary" [matMenuTriggerFor]="ellipsisDocumentMenu"
                    matTooltip="{{'spdf.app.tooltips.moreActions' | translate}}"
                    [disabled]="processingTask || skippingTask"
                    attr.aria-label="{{'spdf.app.tooltips.moreActions' | translate}}">
                    <mat-icon fontSet="far" fontIcon="fa-ellipsis-v">
                    </mat-icon>
                </button>
                <mat-menu #ellipsisDocumentMenu="matMenu" yPosition="below" [overlapTrigger]="false"
                    (click)="$event.stopPropagation();">
                    <button *ngIf="false" [disabled]="processingTask || skippingTask" mat-menu-item>
                        <span>{{"spdf.app.actions.forwardToOtherBu" | translate}}</span>
                    </button>
                    <button *ngIf="false" [disabled]="processingTask || skippingTask" mat-menu-item>
                        <span>{{"spdf.app.actions.reject" | translate}}</span>
                    </button>
                    <button [disabled]="processingTask || skippingTask" (click)="getEmailDetails()" mat-menu-item>
                        <span>{{"spdf.app.actions.emailDetails" | translate}}</span>
                    </button>
                </mat-menu>
                <div class="gt-titlebar-button-group" *ngIf="imgControlCollapsed && navigationControlExpanded">
                    <button mat-icon-button color="primary" [matMenuTriggerFor]="moreOptionsMenuResized"
                        matTooltip="{{'spdf.app.tooltips.moreActions' | translate}}"
                        [disabled]="processingTask || skippingTask"
                        attr.aria-label="{{'spdf.app.tooltips.moreActions' | translate}}">
                        <mat-icon fontSet="far" fontIcon="fa-ellipsis-v">
                        </mat-icon>
                    </button>
                    <mat-menu #moreOptionsMenuResized="matMenu" yPosition="below" [overlapTrigger]="false"
                        (click)="$event.stopPropagation();">
                        <button mat-menu-item [disabled]="processingTask || skippingTask" (click)="zoomOut(false)">
                            {{"spdf.app.tooltips.imgControls.zoomOut" | translate}}</button>
                        <button mat-menu-item [disabled]="processingTask || skippingTask" (click)="zoomIn()">
                            {{"spdf.app.tooltips.imgControls.zoomIn" | translate}} </button>
                        <button mat-menu-item [disabled]="processingTask || skippingTask" (click)="fitToWidth()">
                            {{"spdf.app.module.bu.validation.image.text.fitToWidth" | translate}}</button>
                        <button mat-menu-item [disabled]="processingTask || skippingTask" (click)="fitToHeight()">
                            {{"spdf.app.module.bu.validation.image.text.fitToHeight" | translate}}</button>
                        <mat-divider></mat-divider>
                        <button mat-menu-item [disabled]="processingTask || skippingTask" (click)="rotate('left')">
                            {{"spdf.app.tooltips.imgControls.r2l" | translate}}</button>
                        <button mat-menu-item [disabled]="processingTask || skippingTask" (click)="rotate('right')">
                            {{"spdf.app.tooltips.imgControls.r2r" | translate}}</button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </mat-toolbar>

    <info-banner *ngIf="isAdmin && configApprovalBannerDetails && configApprovalBannerDetails.isPending"
        class="info-banner" [primaryLink]="'spdf.app.module.smartPdf.configuration.details'"
        [primaryLinkRoute]="'/configurationReports'">
        <ng-template>
            {{'spdf.app.module.smartpdf.configuration.notification' | translate}}:
            <strong>{{configApprovalBannerDetails?.name}}</strong>
        </ng-template>
    </info-banner>

    <mat-toolbar #bannerE *ngIf="showErrorBanner" class=" error-banner gt-banner gt-banner-error">
        <mat-icon fontSet="fas" fontIcon="fa-exclamation-circle" class="gt-icon"></mat-icon>
        <span class="cdk-visually-hidden"> Error</span>
        <span class="gt-banner-content mat-body-1">
            <span class="gt-banner-messageblock">{{"spdf.app.module.bu.validation.banner.error" | translate}}</span>
        </span>
        <button mat-icon-button attr.aria-label="{{'spdf.app.tooltips.close' | translate}}"
            (click)="showErrorBanner = false" color="gt-color-info" class="gt-banner-close-btn">
            <mat-icon fontSet="far" fontIcon="fa-times" aria-hidden="false" class="gt-icon"
                matTooltip="{{'spdf.app.tooltips.close' | translate}}">
            </mat-icon>
        </button>
    </mat-toolbar>
    <div class="gt-splitter-container" *ngIf="!taskResponse?.allDocsValidated">

        <as-split *ngIf="!taskResponse?.allDocsValidated" direction="vertical" [gutterSize]="8"
            (dragEnd)="dragEnd($event)">
            <as-split-area [size]="taskResponse?.isLineItemsActive ? splitAreaSizes[0] : 100 " [minSize]="20">
                <as-split direction="horizontal" [gutterSize]="8">
                    <as-split-area [size]="35" [minSize]="20" class="form-split-area" style="overflow: hidden;">
                        <mat-expansion-panel hideToggle [expanded]="true">
                            <mat-expansion-panel-header #uniquePanelName (click)="uniquePanelName._toggle()">
                                <mat-panel-title> {{"spdf.app.module.bu.validation.form.name" | translate}}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div *ngIf="isContentLoading" class="gt-spinner-container">
                                <mat-progress-spinner [diameter]="32" mode="indeterminate">
                                </mat-progress-spinner>
                            </div>
                            <form fxLayout="column" *ngIf="form && !isContentLoading"
                                (keydown.enter)="$event.preventDefault()" [formGroup]="form">
                                <div fxLayout="row wrap" fxLayoutGap="24px grid">
                                    <div *ngFor="let field of visibleFields | fieldSectionPipe : {taxesAndSumsSection:false};let i = index"
                                        [id]="field.fieldCode=='IBAN_NUMBER'?'IBAN_IDENTIFIER': (field.fieldCode=='BANK_ACCOUNT_VALUE'? 'BBAN_IDENTIFIER' : 'defaultId'+i)"
                                        [ngClass]="{'hidden-form-control': hideFormControlsOnUI && field.fieldCode != 'DOCUMENT_TYPE_VALUE' }"
                                        class="form-row no-padding-bottom invoice-form" fxFlex="1 0 {{flexValue}}">
                                        <app-element [fieldList]="visibleFields" [element]="field" [form]="form"
                                            (readFocusEvent)="readFocus($event)"
                                            (onDocTypeChange)="documentTypeChanged($event)"
                                            (onChipSelect)="chipSelectionChanged($event)"
                                            (onChipRemove)="clearChipFocus($event)"
                                            (clearClickEvent)="clear($event)"></app-element>
                                    </div>
                                </div>
                                <!-- new section added for taxes and sums -->
                                <div fxLayout="row wrap" [ngClass]="{'hidden-form-control': hideFormControlsOnUI}">
                                    <mat-expansion-panel-header class="taxes-and-sums-title" #uniquePanelName
                                        (click)="uniquePanelName._toggle()">
                                        <mat-panel-title>
                                            {{tr("spdf.app.module.bu.form.taxesAndSums.heading")}}
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                </div>
                                <div fxLayout="row wrap" fxLayoutGap="24px grid" *ngIf="showValidationError"
                                    class="taxes-warning-message">
                                    <mat-toolbar class="gt-banner gt-banner-warning gt-banner-panel">
                                        <mat-icon fontSet="fas" fontIcon="fa-exclamation-triangle"
                                            class="gt-icon"></mat-icon>
                                        <span class="cdk-visually-hidden">Warning</span>
                                        <span class="gt-banner-content mat-body-1">
                                            <span class="gt-banner-messageblock">{{tr(validationError)}} </span>
                                        </span>
                                    </mat-toolbar>
                                </div>
                                <div fxLayout="row wrap" fxLayoutGap="24px grid">
                                    <div *ngFor="let field of visibleFields | fieldSectionPipe : {taxesAndSumsSection:true}"
                                        [ngClass]="{'hidden-form-control': hideFormControlsOnUI}"
                                        class="form-row no-padding-bottom invoice-form" fxFlex="1 0 {{flexValue}}">
                                        <app-element *ngIf="field.taxesAndSumsSection" [fieldList]="visibleFields"
                                            [element]="field" [form]="form" (readFocusEvent)="readFocus($event)"
                                            (onDocTypeChange)="documentTypeChanged($event)"
                                            (clearClickEvent)="clear($event)"></app-element>
                                    </div>
                                </div>
                                <div *ngIf="taskResponse?.isTaxBreakdownActive" class="taxes-and-sums-grid-section"
                                    [ngClass]="{'hidden-form-control': hideFormControlsOnUI}">
                                    <taxes-and-sums [taxData]="invoiceTaxData"
                                        [showWarning]="form.get('TOTAL_TAX_AMOUNT_VALUE')?.hasError('validationError')?true:false"
                                        [isDisabled]="processingTask || skippingTask"
                                        (updatedTaxData)="updatedInvoiceTaxData($event)"
                                        (isTaxDataValid)="_isTaxDataValid($event)"
                                        (readFocusOnCanvas)="readFocusForGridItems($event)"></taxes-and-sums>
                                </div>
                            </form>
                        </mat-expansion-panel>
                    </as-split-area>
                    <as-split-area [size]="65" class="image-split-area" style="overflow: hidden;">
                        <!-- <div id="docViewer" class="gt-container-xxl"> -->
                        <!-- Image controls toolbar -->
                        <mat-expansion-panel id="docViewer" hideToggle [expanded]="true" class="image-toolbar-panel">
                            <mat-expansion-panel-header #uniquePanelNameImg (click)="uniquePanelNameImg._toggle()">
                                <mat-panel-title> {{"spdf.app.module.bu.validation.image.header" | translate}}
                                </mat-panel-title>
                                <mat-panel-description class="image-panel-description">
                                    <div *ngIf="navigationControlExpanded">
                                        <button mat-icon-button color="primary" class="imageActionIcon"
                                            [disabled]="paginator.page==startIndex || processingTask || skippingTask || loadingImage"
                                            (click)="changePage(paginatorNavigation.FIRST)"
                                            matTooltip="{{'spdf.app.tooltips.imgControls.first' | translate}}"
                                            attr.aria-label="{{'spdf.app.tooltips.imgControls.first' | translate}}">
                                            <mat-icon fontSet="far" fontIcon="fa-arrow-to-left" class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                        <button mat-icon-button color="primary" class="imageActionIcon"
                                            [disabled]="paginator.page==startIndex || processingTask || skippingTask || loadingImage"
                                            (click)="changePage(paginatorNavigation.PREVIOUS)"
                                            matTooltip="{{'spdf.app.tooltips.imgControls.previous' | translate}}"
                                            attr.aria-label="{{'spdf.app.tooltips.imgControls.previous' | translate}}">
                                            <mat-icon fontSet="far" fontIcon="fa-angle-left" class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                        <!-- </div> -->
                                        <input matInput class="paginator-page-text" readonly
                                            value="{{this.paginator.page}} / {{paginator.endIndex}}">
                                        <button mat-icon-button color="primary" class="imageActionIcon"
                                            [disabled]="paginator.page==imageCount || processingTask || skippingTask || loadingImage"
                                            (click)="changePage(paginatorNavigation.NEXT)"
                                            matTooltip="{{'spdf.app.tooltips.imgControls.next' | translate}}"
                                            attr.aria-label="{{'spdf.app.tooltips.imgControls.next' | translate}}">
                                            <mat-icon fontSet="far" fontIcon="fa-angle-right" class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                        <button mat-icon-button color="primary" class="imageActionIcon"
                                            [disabled]="paginator.page==imageCount || processingTask || skippingTask || loadingImage"
                                            (click)="changePage(paginatorNavigation.LAST)"
                                            matTooltip="{{'spdf.app.tooltips.imgControls.last' | translate}}"
                                            attr.aria-label="{{'spdf.app.tooltips.imgControls.last' | translate}}">
                                            <mat-icon fontSet="far" fontIcon="fa-arrow-to-right"
                                                class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                    </div>
                                </mat-panel-description>
                                <div class="image-panel-controls">
                                    <div class="gt-additional-info-content" *ngIf="imgControlExpanded">
                                        <button mat-icon-button color="primary" class="imageActionIcon"
                                            (click)="zoomOut(false)"
                                            [disabled]="processingTask || skippingTask || loadingImage"
                                            matTooltip="{{'spdf.app.tooltips.imgControls.zoomOut' | translate}}"
                                            attr.aria-label="{{'spdf.app.tooltips.imgControls.zoomOut' | translate}}">
                                            <mat-icon fontSet="far" fontIcon="fa-search-minus" class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                        <button mat-icon-button color="primary" class="imageActionIcon"
                                            (click)="zoomIn()"
                                            [disabled]="processingTask || skippingTask || loadingImage"
                                            matTooltip="{{'spdf.app.tooltips.imgControls.zoomIn' | translate}}"
                                            attr.aria-label="{{'spdf.app.tooltips.imgControls.zoomIn' | translate}}">
                                            <mat-icon fontSet="far" fontIcon="fa-search-plus" class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                        <mat-divider vertical style="display: inline;" class="image-toolbar-separator">
                                        </mat-divider>
                                        <button mat-icon-button color="primary" class="imageActionIcon"
                                            (click)="rotate('left')"
                                            [disabled]="processingTask || skippingTask || loadingImage"
                                            matTooltip="{{'spdf.app.tooltips.imgControls.r2l' | translate}}"
                                            attr.aria-label="{{'spdf.app.tooltips.imgControls.r2l' | translate}}">
                                            <mat-icon fontSet="far" fontIcon="fa-undo" class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                        <button mat-icon-button color="primary" class="imageActionIcon"
                                            (click)="rotate('right')"
                                            [disabled]="processingTask || skippingTask || loadingImage"
                                            matTooltip="{{'spdf.app.tooltips.imgControls.r2r' | translate}}"
                                            attr.aria-label="{{'spdf.app.tooltips.imgControls.r2r' | translate}}">
                                            <mat-icon fontSet="far" fontIcon="fa-redo" class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                        <mat-divider vertical style="display: inline;" class="image-toolbar-separator">
                                        </mat-divider>
                                        <button mat-icon-button color="primary" [matMenuTriggerFor]="moreOptionsMenu"
                                            class="imageActionIcon" [matMenuTriggerRestoreFocus]="false"
                                            [disabled]="processingTask || skippingTask"
                                            matTooltip="{{'spdf.app.tooltips.moreActions' | translate}}"
                                            attr.aria-label="{{'spdf.app.tooltips.moreActions' | translate}}">
                                            <mat-icon fontSet="far" fontIcon="fa-ellipsis-v" class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                        <mat-menu #moreOptionsMenu="matMenu" yPosition="below" [overlapTrigger]="false"
                                            (click)="$event.stopPropagation();">
                                            <button mat-menu-item (click)="fitToWidth()" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{"spdf.app.module.bu.validation.image.text.fitToWidth" | translate}}
                                            </button>
                                            <button mat-menu-item (click)="fitToHeight()" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{"spdf.app.module.bu.validation.image.text.fitToHeight" |
                                                translate}}</button>
                                            <button mat-menu-item [disabled]="processingTask || skippingTask"
                                                (click)="downloadInvoice()">
                                                {{"spdf.app.tooltips.imgControls.downloadpdf" | translate}}</button>
                                        </mat-menu>
                                    </div>
                                    <!-- <div class="gt-titlebar-button-group" fxHide fxShow.lt-md> -->
                                    <div class="gt-additional-info-content"
                                        *ngIf="imgControlHidden && navigationControlExpanded">
                                        <button mat-icon-button class="imageActionIcon" color="primary"
                                            [matMenuTriggerFor]="moreOptionsMenuResized"
                                            [matMenuTriggerRestoreFocus]="false"
                                            matTooltip="{{'spdf.app.tooltips.moreActions' | translate}}"
                                            [disabled]="processingTask || skippingTask"
                                            attr.aria-label="{{'spdf.app.tooltips.moreActions' | translate}}">
                                            <mat-icon fontSet="far" fontIcon="fa-ellipsis-v" class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                        <mat-menu #moreOptionsMenuResized="matMenu" yPosition="below"
                                            [overlapTrigger]="false" (click)="$event.stopPropagation();">
                                            <button mat-menu-item (click)="zoomOut(false)" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{"spdf.app.tooltips.imgControls.zoomOut" | translate}}</button>
                                            <button mat-menu-item (click)="zoomIn()" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{"spdf.app.tooltips.imgControls.zoomIn" | translate}} </button>
                                            <button mat-menu-item (click)="fitToWidth()" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{"spdf.app.module.bu.validation.image.text.fitToWidth" |
                                                translate}}</button>
                                            <button mat-menu-item (click)="fitToHeight()" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{"spdf.app.module.bu.validation.image.text.fitToHeight" |
                                                translate}}</button>
                                            <mat-divider></mat-divider>
                                            <button mat-menu-item (click)="rotate('left')" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{"spdf.app.tooltips.imgControls.r2l" | translate}}</button>
                                            <button mat-menu-item (click)="rotate('right')" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{"spdf.app.tooltips.imgControls.r2r" | translate}}</button>
                                            <button mat-menu-item [disabled]="processingTask || skippingTask"
                                                (click)="downloadInvoice()">
                                                {{"spdf.app.tooltips.imgControls.downloadpdf" | translate}}</button>
                                        </mat-menu>
                                    </div>
                                    <div class="gt-additional-info-content"
                                        *ngIf="imgControlHidden && !navigationControlExpanded">
                                        <button mat-icon-button class="imageActionIcon" color="primary"
                                            [matMenuTriggerFor]="moreOptionsMenuResized"
                                            [matMenuTriggerRestoreFocus]="false"
                                            matTooltip="{{'spdf.app.tooltips.moreActions' | translate}}"
                                            [disabled]="processingTask || skippingTask"
                                            attr.aria-label="{{'spdf.app.tooltips.moreActions' | translate}}">
                                            <mat-icon fontSet="far" fontIcon="fa-ellipsis-v" class="imageActionIcon">
                                            </mat-icon>
                                        </button>
                                        <mat-menu #moreOptionsMenuResized="matMenu" yPosition="below"
                                            [overlapTrigger]="false" (click)="$event.stopPropagation();">
                                            <button mat-menu-item (click)="changePage(paginatorNavigation.FIRST)"
                                                *ngIf="!loadingImage"
                                                [disabled]="paginator.page==startIndex || processingTask || skippingTask || loadingImage">
                                                {{"spdf.app.tooltips.imgControls.first" | translate}}</button>
                                            <button mat-menu-item (click)="changePage(paginatorNavigation.LAST)"
                                                *ngIf="!loadingImage"
                                                [disabled]="paginator.page==imageCount || processingTask || skippingTask || loadingImage">
                                                {{"spdf.app.tooltips.imgControls.last" | translate}} </button>
                                            <button mat-menu-item (click)="changePage(paginatorNavigation.NEXT)"
                                                *ngIf="!loadingImage"
                                                [disabled]="paginator.page==imageCount || processingTask || skippingTask || loadingImage">
                                                {{"spdf.app.tooltips.imgControls.next" | translate}}</button>
                                            <button mat-menu-item (click)="changePage(paginatorNavigation.PREVIOUS)"
                                                *ngIf="!loadingImage"
                                                [disabled]="paginator.page==startIndex || processingTask || skippingTask || loadingImage">
                                                {{"spdf.app.tooltips.imgControls.previous" | translate}}</button>
                                            <mat-divider></mat-divider>
                                            <button mat-menu-item (click)="zoomOut(false)" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{"spdf.app.tooltips.imgControls.zoomOut" | translate}}</button>
                                            <button mat-menu-item (click)="zoomIn()" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{"spdf.app.tooltips.imgControls.zoomIn" | translate}} </button>
                                            <button mat-menu-item (click)="fitToWidth()" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{"spdf.app.module.bu.validation.image.text.fitToWidth" |
                                                translate}}</button>
                                            <button mat-menu-item (click)="fitToHeight()" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{"spdf.app.module.bu.validation.image.text.fitToHeight" |
                                                translate}}</button>
                                            <mat-divider></mat-divider>
                                            <button mat-menu-item (click)="rotate('left')" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{"spdf.app.tooltips.imgControls.r2l" | translate}}</button>
                                            <button mat-menu-item (click)="rotate('right')" *ngIf="!loadingImage"
                                                [disabled]="processingTask || skippingTask">
                                                {{"spdf.app.tooltips.imgControls.r2r" | translate}}</button>
                                            <button mat-menu-item [disabled]="processingTask || skippingTask"
                                                (click)="downloadInvoice()">
                                                {{"spdf.app.tooltips.imgControls.downloadpdf" | translate}}</button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                        <div *ngIf="isImageContentLoading" class="gt-spinner-container">
                            <mat-progress-spinner [diameter]="32" mode="indeterminate">
                            </mat-progress-spinner>
                        </div>
                        <div class="canvas-container" [hidden]="loadingImage">
                            <canvas style="position:relative;" id="canvas" class="img-viewer-body">
                                {{"spdf.app.module.bu.validation.image.text.canvasNotSupported" | translate}}
                            </canvas>
                        </div>
                        <div [hidden]="!loadingImage"
                            class="empty-state-custom align-center gt-empty-state-panel-body-container">
                            <div class="gt-row">
                                <p>{{'spdf.app.module.bu.validation.image.error' | translate}}</p>
                            </div>
                            <div class="gt-row">
                                <button mat-stroked-button (click)="downloadInvoice()"
                                    color="primary">{{'spdf.app.tooltips.imgControls.downloadpdf' | translate}}</button>
                            </div>
                        </div>
                        <!-- </div> -->
                    </as-split-area>
                </as-split>
            </as-split-area>
            <as-split-area *ngIf="taskResponse?.isLineItemsActive" [size]="splitAreaSizes[1]" [minSize]="7">
                <div class="inner-container">
                    <invoice-lines [lineItems]="invoiceLineItems" [isDisabled]="processingTask || skippingTask"
                        (updatedLineItems)="updatedInvoiceLineItems($event)"
                        (isLineItemsValid)="_isLineItemsValid($event)"
                        (readFocusOnCanvas)="readFocusForGridItems($event)"></invoice-lines>
                </div>
            </as-split-area>
        </as-split>
    </div>
    <div class="empty-state-section container" *ngIf="taskResponse?.allDocsValidated">
        <mat-tab-group animationDuration="0ms" class="empty-tab-group gt-example-tab-group">
            <mat-tab>
                <div class="gt-empty-state-tab">
                    <div class="gt-row">
                        <p class="gt-lead-text">{{'spdf.app.module.document.validation.states.empty.message' |
                            translate}}</p>
                    </div>
                    <div class="gt-row">
                        <p>{{'spdf.app.module.document.validation.states.empty.description' | translate}}</p>
                    </div>
                    <div class="gt-row gt-button-group-row" [ngClass.xs]="{'gt-row gt-button-group-row':false}">
                        <button mat-button [ngClass.xs]="{'gt-row':true}" color="primary" (click)="reloadPage()">
                            {{'spdf.app.actions.reload' | translate}}
                        </button>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>