import { Pipe, PipeTransform } from "@angular/core";
import { LocalizationService } from "../app-services/localization.service";
import { BaseTranslationService } from "../app-services/base.translation.service";
@Pipe({
  name: 'translate',
  pure: true
})
export class TranslationPipe extends BaseTranslationService implements PipeTransform {
  constructor(localization: LocalizationService) {
    super(localization);
  }
  transform(status: string): string {
    return this.tr(status).toString() || '';
  }
}