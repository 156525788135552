<div mat-dialog-title fxlayout="row">
    <div>{{title | translate}}</div>
    <div class="gt-dialog-close">
        <button mat-icon-button color="primary" attr.aria-label="{{'spdf.app.tooltips.close' | translate}}"
            matTooltip="{{'spdf.app.tooltips.close' | translate}}" mat-dialog-close>
            <mat-icon fontSet="far" fontIcon="fa-times"> </mat-icon>
        </button>
    </div>
</div>

<div mat-dialog-content>

    <form>
        <mat-form-field appearance="legacy" floatLabel="always">
            <mat-label>{{label | translate}}</mat-label>
            <mat-select [(value)]="selectedUser" disableOptionCentering="true"
                placeholder="{{'spdf.app.module.buup.select' | translate}}" required>
                <mat-select-trigger (keydown)="$event.stopPropagation()">
                    <span class="gt-mat-select-trigger-text">{{selectedUser.name + ' (' + selectedUser.email +
                        ')'}}</span>
                    <button class="gt-form-field-clear" mat-icon-button matSuffix
                        matTooltip="{{'spdf.app.tooltips.clear' | translate}}"
                        attr.aria-label="{{'spdf.app.tooltips.clear' | translate}}" *ngIf="selectedUser"
                        (click)="removeSelected($event,selectedUser)"
                        (keydown.enter)="removeSelected($event,selectedUser); $event.stopPropagation()">
                        <mat-icon> cancel </mat-icon>
                    </button>
                </mat-select-trigger>

                <mat-option *ngFor="let user of listOfUsers" [value]="user" cdkMonitorElementFocus>
                    {{ user.name + ' (' + user.email + ')' }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <!-- <mat-spinner *ngIf="creating" diameter="20"></mat-spinner> -->
    <button mat-stroked-button color="primary" mat-dialog-close
        attr.aria-label="{{'spdf.app.module.buup.cancel' | translate}}">
        {{'spdf.app.module.buup.cancel' | translate}}
    </button>
    <button mat-flat-button color="primary" attr.aria-label="{{okButtonText | translate}}" (click)="replaceUsers()"
        [disabled]="!selectedUser">
        {{okButtonText | translate}}
    </button>
</div>