import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { AppConfig } from './app.config';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormFieldComponent } from './dynamic-form/dynamic-form-field.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { GtResponsiveBreakpointsModule } from '@basware/graviton-theme-helpers';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatExpansionModule } from '@angular/material/expansion';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { AppUtils } from './dynamic-form/app-util.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { BusinessUnitComponent } from './business-unit/business-unit.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { AngularSplitModule } from "angular-split";
import { MainAppLayoutComponent } from './main-app-layout/main-app-layout.component';
import { ApiService } from './app-services/api.service';
import { AuthorizationInterceptor } from './app-services/authorization.http.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocalizationService } from './app-services/localization.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ForbiddenDialogComponent } from './error/error.component';
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_FORMATS,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { IconSnackBarComponent } from './snackbar/gt-icon-snack-bar.component';
import { BusinessUnitUserPermissionsComponent } from './business-unit-user-permissions/business-unit-user-permissions.component';
import { EditUserComponent } from './business-unit-user-permissions/dialogs/edit-user/edit-user.component';
import { ClearAllUsersComponent } from './business-unit-user-permissions/dialogs/clear-all-users/clear-all-users.component';
import { EditSingleUnitUsersComponent } from './business-unit-user-permissions/edit-single-unit-users/edit-single-unit-users.component';
import { HighlightSearchPipe } from './pipes/HighlightSearchPipe';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { GtRevealModule } from '@basware/gt-reveal';
import { CommonService } from './app-services/common.service';
import { MatTabsModule } from '@angular/material/tabs';
import { AgGridModule } from 'ag-grid-angular';
import { SupplierGridComponent } from './dynamic-form/dialog/supplier-dialog.component';
import { registerLocaleData } from '@angular/common';
import defaultLocale from '@angular/common/locales/en';
import { GtGridModule, defaultGtGridConfigProvider } from "@basware/gt-grid";
import { UnsavedDataWarningComponent } from './business-unit-user-permissions/dialogs/unsaved-data-warning/unsaved-data-warning.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SendDefaultValuesComponent } from './business-unit-user-permissions/dialogs/send-default-values/send-default-values.component';
import { CustomPaginatorConfig } from './app-services/paginator.config';
import { BaseTranslationService } from './app-services/base.translation.service';
import { ExtendSessionComponent } from './dialogs/extend-session.component';
import { SessionService } from './app-services/session.service';
import { ConfigurationReportsComponent } from './configuration-reports/configuration-reports.component';
import { SmartpdfApiService } from './app-services/smartpdf.api.service';
import { ApprovalDialogComponent } from './configuration-reports/approveDialog/approval-dialog.component';
import { InfoBannerComponent } from './banners/info-banner/info-banner.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EmailDetailsComponent } from './dialogs/email-details.component';
import { MultiInvoiceSettingsComponent } from './multi-invoice-settings/multi-invoice-settings.component';
import { InvoiceSenderDialogComponent } from './multi-invoice-settings/invoice-sender-dialog/invoice-sender-dialog.component';
import { DeleteSettingDialogComponent } from './multi-invoice-settings/delete-setting-dialog/delete-setting-dialog.component';
import { InvoiceLinesComponent } from './dynamic-form/invoice-lines/invoice-lines.component';
import { GtEditableGridModule } from '@basware/gt-editable-grid';
import { EditableValidatingGridComponent } from './dynamic-form/invoice-lines/editable-validating-grid/editable-validating-grid.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { TranslationPipe } from './pipes/TranslationPipe';
import { InvoiceFormFieldSectionPipe } from './dynamic-form/element.section.pipe';
import { TaxesAndSumsComponent } from './dynamic-form/taxes-and-sums/taxes-and-sums.component';
import { EditableValidatingGridForTaxes } from './dynamic-form/taxes-and-sums/editable-validating-grid/editable-validating-grid.component';

declare function aptrinsic(i: any, u: any, a: any): any;

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: AppConfig.DATE_FORMATS.DDMMYYYY
  },
  display: {
    dateInput: AppConfig.DATE_FORMATS.DDMMYYYY,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};
export function appInit(apiService: ApiService, appConfigService: LocalizationService, commonService: CommonService) {
  return () => apiService.UserAppInitializer().then(async (returnedConfig: any) => {
    if (returnedConfig) {
      await appConfigService.setLang(returnedConfig.locale);
      commonService.setBaswareCustomerId(returnedConfig.customerId)
      commonService.setUserLocale(returnedConfig.locale)
      commonService.setAdmin(returnedConfig.admin)
      commonService.setCmosEnv(returnedConfig.cmosEnv)
      AppConfig.NAME_OF_THE_USER = returnedConfig.email;
      AppConfig.USER_CULTURE = returnedConfig.locale;
      apiService.clearCacheApis();

      // gainsight
      aptrinsic('set', 'globalContext', { "system": "smartpdf" });
      aptrinsic('set', 'globalContext', { "smartpdfAdminUser": returnedConfig.admin });
      aptrinsic("identify",
        {
          //User Fields
          "id": returnedConfig.sub,
          "Systemlanguage": ["en-US", "en-UK", "de-DE", "fr-FR", "fi-FI"].includes(returnedConfig.locale) ? returnedConfig.locale : "en-US"
        },
        {
          //Account Fields
          "id": returnedConfig.customerId, // Customer ID
          "name": returnedConfig.tenant_name
        });
      //false for normal user
    }
  });
}

registerLocaleData(defaultLocale);

@NgModule({
  declarations: [
    AppComponent,
    DynamicFormComponent,
    DynamicFormFieldComponent,
    BusinessUnitComponent,
    MainAppLayoutComponent,
    IconSnackBarComponent,
    BusinessUnitUserPermissionsComponent,
    EditUserComponent,
    ClearAllUsersComponent,
    EditSingleUnitUsersComponent,
    HighlightSearchPipe,
    TranslationPipe,
    SupplierGridComponent,
    UnsavedDataWarningComponent,
    SendDefaultValuesComponent,
    ForbiddenDialogComponent,
    ExtendSessionComponent,
    ConfigurationReportsComponent,
    ApprovalDialogComponent,
    InfoBannerComponent,
    EmailDetailsComponent,
    MultiInvoiceSettingsComponent,
    InvoiceSenderDialogComponent,
    DeleteSettingDialogComponent,
    InvoiceLinesComponent,
    EditableValidatingGridComponent,
    ConfirmationDialogComponent,
    InvoiceFormFieldSectionPipe,
    TaxesAndSumsComponent,
    EditableValidatingGridForTaxes
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSnackBarModule,
    GtResponsiveBreakpointsModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatToolbarModule,
    MatDividerModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    AngularSplitModule,
    HttpClientModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatChipsModule,
    MatDialogModule,
    MatSortModule,
    GtRevealModule,
    MatTabsModule,
    AgGridModule,
    MatDialogModule,
    GtGridModule,
    GtEditableGridModule,
    MatAutocompleteModule,
    DragDropModule
  ],
  providers: [AppConfig, AppUtils, ApiService, SmartpdfApiService, defaultGtGridConfigProvider, SessionService,
    defaultGtGridConfigProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [ApiService, LocalizationService, CommonService]
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-US',
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS,
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomPaginatorConfig,
      deps: [LocalizationService, BaseTranslationService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
